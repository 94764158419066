import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import {  Programs, ProgramsFilter } from '@core/interfaces/Programs.interface';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {
  public urlBackEnd: string = environment.base_url;
  public userData: any;
  public headers: any;
  public tokenUser: string = '';
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${localStorage.getItem('token_conhector')}`)
      .set('iduser', `${localStorage.getItem('user_conhector')}`);

  }


  getListPrograms(page = 1, limit = 10): Observable<Programs> {
    const params = new HttpParams()
    .set('page', page.toString())
    .set('limit', limit.toString());
    return this.http
      .get<Programs>(`${this.urlBackEnd}/programs`, { params:params, headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  /**
   * Obtiene una lista paginada de programas.
   * @param {number} page - Número de página.
   * @param {number} limit - Límite de programas por página.
   * @returns {Observable<Programs>} - Observable que emite un objeto con la lista paginada de programas.
  */
  getListProgramsPaginate(page: number, limit: number, filters?: ProgramsFilter): Observable<Programs> {
    //Establecer body de la solicitud
    let body: ProgramsFilter = {};
    //Configurar paramentros de consulta
    const params = new HttpParams()
    .set('page', page.toString())
    .set('limit', limit.toString());
    if (filters)
    {
     if (Object.keys(filters).length > 0) {
      body['filters'] = filters
    }}

    return this.http
      .post<Programs>(`${this.urlBackEnd}/programs/programs-paginate`, body, { params: params, headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  getProgramById(id: string):Observable<Programs>{

    return this.http
    .get<Programs>(`${this.urlBackEnd}/programs/${id}`, { headers: this.headers})
    .pipe(
      catchError((error: HttpErrorResponse) =>{
        let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
        if(error.error instanceof ErrorEvent){
          console.error('Error del cliente:', error.error.message)
        }else{
          console.error(
          `Error en la solicitud de getProgramById: ${error.status},` +
          `Mensaje de error: ${error.error.msg}`
          )
          errorMessage= error.error.msg || errorMessage;
        }
        return throwError(error);
      })
    )

  }

  getListTypePrograms(): Observable<Programs> {
    return this.http
      .get<Programs>(`${this.urlBackEnd}/programs/`, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
    }

  getListFilterTypePrograms(): Observable<Programs> {
    return this.http
      .get<Programs>(`${this.urlBackEnd}/programs/FilterType`, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListFilterTypePrograms: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  /**
   * Obtiene los filtros disponibles para la búsqueda de programas.
   * @returns {Observable<Programs>} - Observable que emite un objeto con los filtros disponibles.
  */
  getListFiltersPrograms(): Observable<ProgramsFilter> {

    return this.http
      .get<Programs>(`${this.urlBackEnd}/programs/programs-filters`, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de los participantes';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }


  registerNewProgram(data: Programs): Observable<Programs> {
    return this.http
      .post<Programs>(`${this.urlBackEnd}/programs`, data, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al registrar el programa';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de registerNewProgram: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }


  updateProgram(id: string, data:Programs): Observable<Programs> {
    return this.http
      .put<Programs>(`${this.urlBackEnd}/programs/${id}`, data, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al actualizar el programa';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de updateProgram: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }
}
