import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { Program, ProgramsFilter } from '@core/interfaces/Programs.interface';
import { FilterConfig } from '@core/interfaces/filters.interface';
import { ProgramsService } from '@core/services/programs/programs.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import { FilterService } from '@core/services/filters/filter.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Subcategory } from '../../../../core/interfaces/Programs.interface';
import { DialogViewProgramComponent } from '@modules/programs/components/dialog-view-program/dialog-view-program.component';
import { StepsService } from '@core/services/programs/steps.service';
import { Step, Steps } from '@core/interfaces/Steps.interface';

/**
 * Componente para listar programas con paginación y filtros.
 */
@Component({
  selector: 'app-list-programs',
  templateUrl: './list-programs.component.html',
  styleUrls: ['./list-programs.component.scss'],
})
export class ListProgramsComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Texto del botón de creación de programa. */
  public titleButton = 'Crear Programa';

  /** Evento de cambio de página. */
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();

  /** Número de página actual. */
  public pageIndex: number = 1;
  /** Tamaño de la página actual. */
  public pageSize: number = 50;
  /** Número total de elementos. */
  public totalItems?: number;
  /** Columnas a mostrar en la tabla. */
  displayedColumns: string[] = ['program_name', 'type', 'Subcategory', 'menu_option', 'state', 'action'];
  /** Configuración de los filtros. */
  filterConfigs: FilterConfig[] = [];
  /** Suscripción a los cambios de filtros. */
  private filterSubscription: Subscription | undefined;
  /** Lista de programas. */
  public listPrograms: Program[] = [];
  /** Filtros actuales aplicados a los programas. */
  public dataFilters: ProgramsFilter = {};

  /** Almacena los datos recuperados de la paginación para el uso de caché. */
  public cachedData: { [key: number]: Program[] } = {};

  /** Paginator de la tabla. */
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  /** Data source para la tabla. */
  dataSource = new MatTableDataSource<Program>(this.listPrograms);

  public listSteps: Step[] = [];

  /**
   * Constructor del componente.
   */
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private filterService: FilterService,
    public programsService: ProgramsService,
    public themeService: CustomizerSettingsService,
    private _stepService: StepsService,
  ) {}

  /**
   * Método de inicialización del componente.
   */
  ngOnInit(): void {
    // Obtener configuración de filtros al iniciar
    this.programsService.getListFiltersPrograms().subscribe((resp: any) => {
      const types: string[] = Object.keys(resp.filters);

      // Configuración de los filtros
      this.filterConfigs = [
        {
          name: 'Tipo',
          icon: 'ri-list-check-2',
          options: types,
          dbName: 'type',
        },
        {
          name: 'Categoria',
          icon: 'ri-bar-chart-horizontal-fill',
          options: [],
          dbName: 'Subcategory',
        },
        {
          name: 'Estado',
          icon: 'ri-bard-line',
          options: [],
          dbName: 'state',
          pipe: 'state',
        },
      ];

      // Pasar la configuración de filtros al servicio
      this.filterService.setFilterConfigs(this.filterConfigs, resp.filters);
    });

    // Suscripción a los cambios de filtros
    this.filterSubscription = this.filterService.filter$.subscribe((filters) => {
      const selectedCategoryData = this.filterService.getFilterDataByCategory(filters.type);
      if (filters.type) {
        if (selectedCategoryData) {
          this.filterConfigs.find((config) => config.name === 'Categoria')!.options = selectedCategoryData.Subcategory ?? "";
          this.filterConfigs.find((config) => config.name === 'Estado')!.options = selectedCategoryData.state ?? "";
        }
      }

      for (const itemFilter in filters) {
        if (itemFilter !== 'searchValue') {
          if (selectedCategoryData[itemFilter]) {
            const value = selectedCategoryData[itemFilter].find((value: any) => value === filters[itemFilter]);
            console.log(value)
            if (value) {
              filters[itemFilter] = value;
            } else {
              delete filters[itemFilter];
            }
          }
        }
      }

      // Obtener la lista de programas paginada con los filtros aplicados
      this.getListProgramsPaginate(filters);
      this.dataFilters = filters;
    });

    // Obtener la lista inicial de programas paginada
    this.getListProgramsPaginate();
  }

  /**
   * Método que se ejecuta después de la inicialización de la vista.
   */
  ngAfterViewInit(): void {
    if (this.paginator) {
      // Manejar la paginación de la tabla
      this.paginator.page.subscribe((event: PageEvent) => {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        // Verificar si los datos ya están en caché antes de hacer la consulta
        if (this.cachedData[this.pageIndex] && this.cachedData[this.pageIndex].length === this.pageSize) {
          this.listPrograms = this.cachedData[this.pageIndex];
          this.dataSource.data = this.listPrograms;
        } else {
          this.getListProgramsPaginate();
        }
      });
    }
  }

  /**
   * Método de destrucción del componente.
   */
  ngOnDestroy(): void {
    // Destruir la suscripción
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  /**
   * Obtiene la lista de programas con paginación.
   * @param filters Filtros aplicados a la consulta.
   */
  getListProgramsPaginate(filters?: any) {
    this.programsService.getListProgramsPaginate(this.pageIndex, this.pageSize, filters).subscribe(
      (resp: any) => {
        if (resp.ok) {
          this.listPrograms = resp.results.data;
          this.dataSource.data = this.listPrograms;
          this.totalItems = resp.results.pagination.total;
          this.dataSource = new MatTableDataSource(this.listPrograms);

          // Actualizar los datos en caché con el nuevo tamaño de la página
          this.cachedData[this.pageIndex] = this.listPrograms;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * Navega a la página de edición de un programa.
   * @param event Evento con los datos del programa a editar.
   */
  editProgram(event: any) {
    this.router.navigate(['programs/edit_program'], {
      queryParams: { id: event._id },
    });
  }

  /**
   * Navega al dialogo de vista del programa.
   * @param event Evento con los datos del programa a ver.
   */
  openViewProgramDialog(
    data: any
  ): void {
    const dialogRef = this.dialog.open(DialogViewProgramComponent, {
      width: '70vw',
      data:  data,
    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }

  getListStepPrograms(event: any) {
    this._stepService.getStepByProgram(event._id).subscribe(
      (resp: Steps) => {
        if (resp.ok) {
          this.listSteps = resp.results;
          this.openViewProgramDialog( {program: event, listSteps: this.listSteps} )
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * Maneja el cambio de página del paginador.
   * @param event Evento de cambio de página.
   */
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageChange.emit({ pageIndex: this.pageIndex, pageSize: this.pageSize });
    this.getListProgramsPaginate(this.dataFilters);
  }
}
