
  <div class="dialog-header">
    <h2 mat-dialog-title class="dialog-title">Verificación de Campaña</h2>
    <button mat-icon-button class="close-button" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content *ngIf="campaign">
    <div class="container">
      <div class="form-container row">
        <div class="form-section col-lg-6 col-md-6 col-sm-12">
          <h3 class="section-title">Código QR de la Campaña</h3>
          <div class="d-flex justify-content-center">
            <img class="qr-code" [src]="campaign.qr_image_url" alt="QR Code" />
          </div>
          <div class="tagus-form-group">
            <form class="form-full-width mt-10">
              <mat-form-field class="input-full-width" appearance="fill">
                <i class="ri-qr-code-line"></i>
                <mat-label>Código QR (data)</mat-label>
                <input readonly matInput name="qrdata" placeholder="qrdata" [(ngModel)]="this.campaign.deep_link_url" />
                <button mat-icon-button matSuffix color="primary" (click)="copyToClipboard()">
                  <mat-icon class="blue-color">file_copy</mat-icon>
                </button>
              </mat-form-field>
            </form>
          </div>
        </div>
        <div class="preview-section col-lg-6 col-md-6 col-sm-12">
          <h3 class="section-title">Mensajes</h3>
          <app-phone-view
          [phoneWidth]="'300px'"
          [phoneHeight]="'height: 620px;'"
          [sentMessage]="sentMessage"
          [questions]="questions"
          ></app-phone-view>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-button class="btn-edit" (click)="onEdit()">Editar</button>
    <button mat-button class="btn-cancel" (click)="onCancel()">Cancelar</button>
  </mat-dialog-actions>

