import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from '../material.module';
import { SharedRoutingModule } from './shared-routing.module';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InternalErrorComponent } from './internal-error/internal-error.component';
import { CustomizerSettingsComponent } from './customizer-settings/customizer-settings.component';
import { ConstructionSiteComponent } from './construction-site/construction-site.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';

const sharedExports = [
  SidebarComponent,
  HeaderComponent,
  FooterComponent,
  InternalErrorComponent,
  NotFoundComponent,
  CustomizerSettingsComponent,
  ConstructionSiteComponent,
  SpinnerComponent,
  BreadcrumbsComponent,
];

@NgModule({
  declarations: [sharedExports],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    NgScrollbarModule,
  ],
  exports: [sharedExports],
  providers: [DatePipe],
})
export class SharedModule {}
