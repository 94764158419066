<header
    class="header-area bg-white border-bottom-radius box-shadow mb-15"
    [ngClass]="{'active': isToggled, 'sticky': isSticky}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.dark-header]="themeService.isHeaderDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-flex align-items-center justify-content-space-between">
        <div class="header-left-side d-flex align-items-center">
            <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
        <div class="header-center">
            <div class="header-image">
                <img src="assets/img/ConHector/Conhector-horizontal.png" alt="Con">
            </div>
        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
            <li>
                <!-- <button mat-icon-button [matMenuTriggerFor]="notificationMenu" class="btn p-0 border-none rounded-circle position-relative notification-btn">
                    <i class="flaticon-bell-2"></i>
                </button> -->
                <mat-menu class="menu-dropdown" #notificationMenu="matMenu" xPosition="before">
                    <!-- <div class="menu-header d-flex align-items-center justify-content-space-between">
                        <h5 class="m-0">Notificaciones</h5>
                        <button type="button" class="bg-transparent p-0 border-none">Mark all as read</button>
                    </div> -->
                    <ul class="menu-body">
                        <li class="position-relative">
                            <div class="d-flex">
                                <i class="flaticon-file-1 icon"></i>
                                <div class="title">
                                    <h6>8 Invoices have been paid</h6>
                                    <span><i class="flaticon-timer"></i> 14 mins ago</span>
                                </div>
                            </div>
                            <a routerLink="/notifications" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i class="flaticon-close"></i></button>
                        </li>
                        <li class="position-relative">
                            <div class="d-flex align-items-center">
                                <i class="flaticon-pdf-file icon"></i>
                                <div class="title">
                                    <h6>Allow users to like products</h6>
                                    <span><i class="flaticon-timer"></i> 30 mins ago</span>
                                </div>
                            </div>
                            <a routerLink="/notifications" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i class="flaticon-close"></i></button>
                        </li>
                        <li class="position-relative">
                            <div class="d-flex align-items-center">
                                <i class="flaticon-to-do-list icon"></i>
                                <div class="title">
                                    <h6>Live visits on our site</h6>
                                    <span><i class="flaticon-timer"></i> 5 hours ago</span>
                                </div>
                            </div>
                            <a routerLink="/notifications" class="link-btn"></a>
                            <button class="close-btn bg-transparent border-none p-0"><i class="flaticon-close"></i></button>
                        </li>
                    </ul>
                    <div class="menu-footer">
                        <a routerLink="/notifications" class="fw-medium position-relative">View All <i class="flaticon-chevron"></i></a>
                    </div>
                </mat-menu>
            </li>
            <li class="d-none d-lg-block">
                <div class="date-btn title-color  fw-semibold position-relative ">
                    <i class="flaticon-calendar"></i> {{ formattedDate }}
                </div>
            </li>
        </ul>
    </div>
</header>
