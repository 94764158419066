import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { Auth } from '@core/interfaces/auth.interface';
import { AuthService } from '@core/services/authentication/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  hide = true;
  saludoImg =
    '../../../../assets/img/ConHector/Personaje_acciones/ConHector_1.png';
  errorImg =
    '../../../../assets/img/ConHector/Personaje_acciones/ConHector_Dudoso.png';
  internalErrorImg = '../../../../assets/img/error_icons/error_500.gif';
  lockedUserImg = '../../../../assets/img/error_icons/locked.gif';

  public loginForm = this.fb.group({
    email: [
      localStorage.getItem('email') || '',
      [Validators.required, Validators.email, this.emailDomainValidator],
    ],
    password: ['', Validators.required],
    remember: [false],
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) {}

  emailDomainValidator(control: any) {
    const email = control.value;
    if (email && email.indexOf('@') !== -1) {
      const [_, domain] = email.split('@');
      if (domain.toLowerCase() !== 'fundacioncapital.org') {
        return { invalidDomain: true };
      }
    }
    return null;
  }

  login() {
    const { email } = this.loginForm.value;
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.loginService(this.loginForm.value).subscribe(
      (resp: Auth) => {
        if (resp.ok) {

            localStorage.setItem('user_conhector', resp.user?.id || '');
            localStorage.setItem('token_conhector', resp.user?.token || '');
           /*  Swal.fire({
              title: 'Bienvenido',
              text: 'Haz ingresado al cms de CON HECTOR',
              imageUrl: this.saludoImg,
              imageWidth: 100,
              imageHeight: 200,
              imageAlt: 'Custom image',
            }); */
            this.router.navigate(['/']);

        }
      },
      (error) => {
        if (error.status === 500) {
          Swal.fire({
            title: 'Upps!!',
            text: 'Se ha presentado un error interno en el servidor, por favor intente más tarde. O comunicate con Sistemas',
            imageUrl: this.internalErrorImg,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',
          });
        } else if (error.status === 401) {
          Swal.fire({
            title: 'Upps!!',
            text: 'Tu cuenta ha sido bloqueada por seguridad, a tu correo se ha enviado un mensaje para desbloquearla',
            imageUrl: this.lockedUserImg,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',
          });
        } else {
          Swal.fire({
            title: 'Upps!!',
            text:
              error.error.msg ||
              'Ocurrió un error en la solicitud de inicio de sesión',
            imageUrl: this.errorImg,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',
          });
        }
      }
    );
  }
}
