<app-dialog [title]="'Paso'" (saveEvent)="eventStep()" [buttonClose]="false" class="dialog-container" (closeEvent)="close()">
  <form [formGroup]="step" class="form-wrapper">
    <div class="row container">

      <div class="form-section tagus-form-group mb-0 col-lg-12 col-md-6 col-sm-12">
        <label class="d-block mb-12 fw-semibold tertiary-blue-color ">Interacción</label>
        <mat-form-field appearance="fill" class="full-width">
          <i class="ri-douban-fill"></i>
          <mat-label>Interacción</mat-label>
          <mat-select formControlName="interaction">
            <mat-option disabled>Selecciona</mat-option>
            <mat-option value="Informativo">Informativo</mat-option>
            <mat-option value="Pregunta">Pregunta</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <i class="ri-align-left"></i>
          <mat-label>Descripción</mat-label>
          <textarea matInput rows="15" formControlName="description"></textarea>
        </mat-form-field>
      </div>

      <div class="media-section col-lg-12 col-md-6 col-sm-12">
        <h5 class="text-center tertiary-blue-color ">Selecciona o Carga Multimedia</h5>
        <p class="instruction text-center">Puedes agregar un enlace o cargar una nueva multimedia al mismo tiempo, pero solo se seleccionará uno.</p>

        <div class="tagus-form-group">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Título</mat-label>
            <i class="ri-heading"></i>
            <input matInput formControlName="title">
          </mat-form-field>
        </div>

        <div class="tagus-form-group">
          <ngx-dropzone (change)="onSelect($event)" class="dropzone" [disabled]="step.get('fileUrl')?.value || false">
            <ngx-dropzone-label>Arrastra y suelta archivos o haz clic aquí para seleccionarlos.</ngx-dropzone-label>
            <ngx-dropzone-image-preview *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
        </div>

        <div class="tagus-form-group">
          <mat-form-field appearance="fill" class="full-width">
            <i class="ri-link"></i>
            <mat-label>Imagen o video (enlace)</mat-label>
            <input matInput formControlName="fileUrl" (input)="checkFileUrl()" [disabled]="files.length > 0">
          </mat-form-field>
        </div>

        <div class="tagus-form-group text-right " style="text-align: right;">
          <button mat-raised-button class="bg-main-color-blue default-btn" (click)="saveMedia()" [disabled]="files.length === 0 && !step.get('fileUrl')?.value">  Subir  </button>
        </div>
      </div>
    </div>
  </form>

  <h3 class="text-center">Listado de Multimedia</h3>
  <app-table-data
    [data]="listMultimedias"
    [dataSource]="dataSource"
    [columns]="displayedColumns"
    [pageSizeOptions]="[10, 20, 50, 100, 200]"
    [radioButton]="true"
    [totalItems]="totalItems"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (radioEvent)="selectMedia($event)"
    (pageChange)="onPageChange($event)">
  </app-table-data>

</app-dialog>
