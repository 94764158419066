import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { InteractionsMsgComponent } from './interactions-msg/interactions-msg.component';
import { MaterialModule } from 'src/app/material.module';
import { CoreModule } from '@core/core.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { ChatSidebarComponent } from './interactions-msg/chat-sidebar/chat-sidebar.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [
    InteractionsMsgComponent,
    ChatSidebarComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MaterialModule,
    CoreModule,
    ComponentsModule,
    NgScrollbarModule,
  ]
})
export class DashboardModule { }
