<div class="login-page h-100vh">
  <!-- <div class="header">
    <div class="header-image">
      <img class="" src="assets/img/ConHector/Conhector-horizontal.png" alt="Con">
    </div>
  </div> -->
  <div class="login-container">
    <div class="login-image">
      <img  src="assets/img/ConHector/Personaje_acciones/ConHector_1.png" alt="Con-Hector">
    </div>
    <div class="login-form-container">
      <div class="tagus-form ms-auto me-auto">
        <h2 class="text-center mt-20">INICIAR SESIÓN</h2>
        <form autocomplete="off" [formGroup]="loginForm" id="loginform">
          <div class="bg-white border-radius pt-20 ps-20 pe-20 pb-20">
            <div class="tagus-form-group without-icon">
              <label class="d-block mb-12 fw-semibold font-color">Correo electrónico</label>
              <mat-form-field appearance="fill" class="w-100">
                <mat-icon matPrefix class="icon-color">mail</mat-icon>
                <input matInput formControlName="email" placeholder="Correo electrónico" required>
                <!-- Mensajes de error -->
                <mat-error *ngIf="loginForm.get('email')?.hasError('required')">
                  El correo es requerido
                </mat-error>
                <mat-error *ngIf="loginForm.get('email')?.hasError('email')">
                  Ingrese un correo válido
                </mat-error>
                <mat-error *ngIf="loginForm.get('email')?.hasError('invalidDomain')">
                  Solo se permiten correos de fundacioncapital.org
                </mat-error>
              </mat-form-field>
            </div>
            <div class="tagus-form-group without-icon">
              <label class="d-block mb-12 fw-semibold font-color">Contraseña</label>
              <mat-form-field appearance="fill" class="w-100">
                <mat-icon matPrefix class="icon-color">lock</mat-icon>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Contraseña" required>
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="d-flex info align-items-center justify-content-space-between">
              <mat-checkbox class="icon-color font-color" style="color: red !important;">Recordar mis datos</mat-checkbox>
              <a routerLink="/forgot-password" class="d-inline-block main-color fw-medium">¿Olvidaste tu contraseña?</a>
            </div>
            <div class="d-flex info align-items-center justify-content-center">
              <button mat-flat-button class="tagus d-block fw-semibold mt-20 w-100 login-button" (click)="login()" [disabled]="loginForm.invalid">Ingresar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
