import { Injectable } from '@angular/core';
import { FilterConfig } from '@core/interfaces/filters.interface';
import { Observable, BehaviorSubject, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filterSubject = new Subject<any>();
  private filterConfigSubject = new Subject<any>();
  private filterData: any = {}; // Propiedad para almacenar los datos de los filtros

  public filter$ = this.filterSubject.asObservable().pipe(
    catchError((error) => {
      console.error('Error filtering data:', error);
      return throwError(error); // Re-throw the error for proper handling
    })
  );

  public filterConfig$ = this.filterConfigSubject.asObservable().pipe(
    catchError((error) => {
      console.error('Error getting filter configs:', error);
      return throwError(error); // Re-throw the error for proper handling
    })
  );

  constructor() {}

  public setFilters(filter: any): void {
    this.filterSubject.next(filter);
  }

  public getFilters(): Observable<any> {
    return this.filter$;
  }

  public getFilterDataByCategory(category: string) {
    return this.filterData[category] || null;
  }

  public setFilterConfigs(configs: any, response?: any): void {

    this.filterData = {};
    // Almacenar los datos de filtro de manera general
    let subCategory: string[] = [];
    for (let category of  Object.keys(response)) {
      subCategory.push(category)
      this.filterData[category] = response[category];
    }
    this.filterConfigSubject.next(configs);
  }

  public getFilterConfigs(): Observable<FilterConfig[]> {
    return this.filterConfig$;
  }
}
