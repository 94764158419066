import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project, Projects } from '@core/interfaces/project.interface';
import { User, Users } from '@core/interfaces/User.interface';
import { ProjectsService } from '@core/services/configurations/projects.service';
import { UsersService } from '@core/services/configurations/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-edit-user',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.scss']
})
export class CreateEditUserComponent implements OnInit {

  public listProject:Project[]=[];

  public userForm = this.fb.group({
    img: [''],
    name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    phone:['', Validators.required],
    project: ['', Validators.required]
  });

  constructor(
    public fb: FormBuilder,
    public _userService: UsersService,
    public _projectService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditUserComponent>
  ) { }

  ngOnInit(): void {
    this.getListProject();

    if (this.data && this.data.event ) {
      this.userForm.patchValue({
        img: this.data.event.img,
        name: this.data.event.name,
        last_name: this.data.event.last_name,
        email: this.data.event.email,
        phone: this.data.event.phone,
        project: this.data.event.project
      });

    }

  }

  getListProject():void{

    this._projectService.getProjectsList().subscribe(
      (resp: Projects) =>{
        this.listProject = resp.results;
      }, err =>{
        console.log(err);
      }

   );
  }


   eventData(){
    if (this.data && this.data.event) {
      this.updateUser();
    }else{
      this.createUser();
    }


  }

  createUser(){
    if(this.userForm.valid){

      let userNew: User = {
        img: this.userForm.get('img')?.value || '',
        name: this.userForm.get('name')?.value|| '',
        last_name: this.userForm.get('last_name')?.value|| '',
        email: this.userForm.get('email')?.value|| '',
        phone: this.userForm.get('phone')?.value|| '',
        project: this.userForm.get('project')?.value|| ''

      }

      this._userService.createUser(userNew).subscribe(
        (resp: any)=>{
            if (resp.ok) {
             Swal.fire('Usuario creado', 'Usuario creado correctamente', 'success');
             this.dialogRef.close();
             }
        }, err =>{
          console.log(err);
        }
      );
    }else{
      console.log('Formulario invalido');
    }
  }


  updateUser() {
    let id = this.data.event.id

    this._userService.UpdateUser(id, this.userForm.value as User).subscribe(
      (res: Users) => {
        if (res.ok) {
          Swal.fire({
            title: 'Usuario actualizado',
            text: 'Usuario actualizado correctamente',
            icon: 'success',
            confirmButtonText: 'cerrar',

          });

          this.dialogRef.close();

        }
      },
      (err) => {
        Swal.fire({
          title: 'Error',
          text: 'Error al actualizar el usuario',
          icon: 'error',
          confirmButtonText: 'cerrar',
        });

      }
    );
  }


  closeEvent(){
    this.dialogRef.close();
  }

}


