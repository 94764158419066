<mat-dialog-content>
  <div class="crop-container" #cropContainer>
    <img [src]="data.imagePreview" alt="Image to crop" class="cropper-image" #imageElement>
    <div class="crop-area" #cropArea (mousedown)="startCrop($event)"></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button class="tagus fw-semibold bg-main-color-blue-button" (click)="cropImage()">Recortar</button>
  <button mat-button class="cancel-button bg-main-color-orange-button" mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
