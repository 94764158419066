<app-dialog title="{{title}}" [buttonClose]="false" (saveEvent)="close()" (closeEvent)="close()">
  <h2 class="section-title title-color">Detalles del Programa</h2>
  <div class="program-details mb-30">
    <div class="row">
      <!-- Nombre del programa -->
      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Nombre del programa</label>
        <p class="program-value">{{ programs.value.program_name }}</p>
      </div>

      <!-- Tipo -->
      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Tipo</label>
        <p class="program-value">{{ programs.value.type }}</p>
      </div>

      <!-- Cobertura -->
      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Cobertura</label>
        <p class="program-value">{{ programs.value.coverage }}</p>
      </div>

      <!-- Subcategoría -->
      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Categoría</label>
        <p class="program-value">{{ programs.value.Subcategory }}</p>
      </div>

      <!-- Fechas -->
      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Fecha de inicio - Fecha Final</label>
        <p class="program-value">Desde {{ programs.value.start_date | date }} hasta {{ programs.value.end_date | date }}</p>
      </div>

      <!-- Opciones del Menú -->
      <div class="col-lg-12 col-md-12 col-sm-12">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Opciones del Menú</label>
        <p class="program-value">{{ programs.value.menu_option }}</p>
      </div>
    </div>
  </div>

  <h2 class="section-title title-color">Listado de Pasos</h2>
  <div class="table-container">
    <div class="table-wrapper">
      <table mat-table [dataSource]="listSteps" class="table-responsive scrollable-table">
        <ng-container matColumnDef="numberStep">
          <th mat-header-cell *matHeaderCellDef class="sticky-header">Posición</th>
          <td mat-cell *matCellDef="let step">{{ step.numberStep }}</td>
        </ng-container>

        <ng-container matColumnDef="media">
          <th mat-header-cell *matHeaderCellDef class="sticky-header">Multimedia</th>
          <td mat-cell *matCellDef="let step">
            <ng-container *ngIf="step.media; else noMedia">
              <img *ngIf="step.media!.type === 'image' || 'image/png'" [src]="step.media!.fileUrl" alt="imagen" width="50" height="50" />
              <img *ngIf="step.media!.type === 'document'" src="assets/img/PDF_file_icon.svg" [alt]="step.media!.name" width="50" height="50" />
              <img *ngIf="step.media!.type === 'audio'" src="assets/img/note_voice.png" [alt]="step.media!.name" width="50" height="50" />
              <img *ngIf="step.media!.type === 'video'" src="assets/img/youtube.svg" [alt]="step.media!.name" width="50" height="50" />
            </ng-container>
            <ng-template #noMedia>
              <p class="subtitle-card-color mb-0">Sin multimedia</p>
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="interaction">
          <th mat-header-cell *matHeaderCellDef class="sticky-header">Interacción</th>
          <td mat-cell *matCellDef="let step">{{ step.interaction }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="sticky-header">Descripción</th>
          <td mat-cell *matCellDef="let step">
            <p class="subtitle-card-color mb-0" *ngIf="step.interaction === 'Informativo'" matTooltip="{{ step.description ?? '' }}">
              {{ step.description ?? '' | truncate:20 }}
            </p>
            <p class="subtitle-card-color mb-0" *ngIf="step.interaction === 'Pregunta'" matTooltip="{{ step.question ?? '' }}">
              {{ step.question ?? '' | truncate:20 }}
            </p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['numberStep', 'media', 'interaction', 'description']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['numberStep', 'media', 'interaction', 'description']"></tr>
      </table>
    </div>
  </div>

</app-dialog>
