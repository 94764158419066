import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

// Interface
import { Programs } from '@core/interfaces/Programs.interface';
import { Step, Steps } from '@core/interfaces/Steps.interface';
import { StepsService } from '@core/services/programs/steps.service';
import {
  SubCategory,
  SubCateogries,
} from '@core/interfaces/subCategoriesProgram.interface';
// Services
import { ProgramsService } from '@core/services/programs/programs.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import { SubCategoriesProgramsService } from '@core/services/configurations/sub-categories-programs.service';
// components
import { DialogMediaComponent } from '@modules/programs/components/dialog-media/dialog-media.component';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-edit-programs',
  templateUrl: './create-edit-programs.component.html',
  styleUrls: ['./create-edit-programs.component.scss'],
})
export class CreateEditProgramsComponent implements OnInit {
  public listCategory: SubCategory[] = [];
  public listTypePrograms: any[] = [];
  public listSteps: Step[] = [];
  public newSteps: Step[] = [];
  public isAddingNewCategory :boolean = false;
  public subcategoryEl: any = '';

  public idProgram: string = '';

  typeForm = new FormControl('');

  filteredOptions: Observable<any[]> | undefined;

  public listCoverage = [
    {
      name: 'Sprint 1',
    },
    {
      name: 'Sprint 2',
    },
    {
      name: 'Sprint 3',
    },
    {
      name: 'Sprint 4',
    },
    {
      name: 'Sprint 5',
    },
    {
      name: 'Sprint 6',
    },
    {
      name: 'Sprint 7',
    },
    {
      name: 'Sprint 8',
    },
    {
      name: 'Sprint 9',
    },
    {
      name: 'Sprint 10',
    },
    {
      name: 'Sprint 11',
    },
    {
      name: 'Sprint 12',
    },
    {
      name: 'Sprint 13',
    },
    {
      name: 'Sprint 14',
    },
    {
      name: 'Sprint 15',
    },
    {
      name: 'Sprint 16',
    },
    {
      name: 'Sprint 17',
    },
    {
      name: 'Sprint 18',
    },
    {
      name: 'Sprint 19',
    },
    {
      name: 'Sprint 20',
    },
  ];

  public programs!: FormGroup;

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route : Router,
    private router: ActivatedRoute,
    public _stepService: StepsService,
    public _programsService: ProgramsService,
    public themeService: CustomizerSettingsService,
    public _subCateogryService: SubCategoriesProgramsService
  ) {}

  ngOnInit(): void {
    this.idProgram = this.router.snapshot.queryParamMap.get('id') || '';
    if (this.idProgram) {
      this.getProgramById(this.idProgram);
    }

    this.getSubCategories();
    this.getListTypePrograms();

    this.filteredOptions = this.typeForm.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    this.programs = this.fb.group({
      program_name: ['', Validators.required],
      type: [''],
      menu_option: ['', Validators.required],
      state: ['A'],
      coverage: [''],
      Subcategory: [''],
      start_date: [''],
      end_date: [''],
    });
  }

  getSubCategories() {
    this._subCateogryService.getListSubCategory().subscribe(
      (resp: SubCateogries) => {
        this.listCategory = resp.results;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getListTypePrograms() {
    this._programsService.getListFilterTypePrograms().subscribe(
      (resp) => {
        this.listTypePrograms = resp.results;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  /**
   *  This function gets the program by id and displays a success or error message.
   * @param id, string - The id of the program.
   */

   getProgramById(id: string) {
    this._programsService.getProgramById(id).subscribe(
      (res: Programs) => {

        this.programs.patchValue({
          program_name: res.results[0].program_name,
          type: res.results[0].type,
          menu_option: res.results[0].menu_option,
          state: res.results[0].state,
          coverage: res.results[0].coverage,
          Subcategory: res.results[0].Subcategory,
          start_date: res.results[0].start_date,
          end_date: res.results[0].end_date,
        });
        if(res.results[0].type) {
          this.typeForm.setValue(this.programs.value.type);
        }
        this.subcategoryEl = res.results[0].Subcategory;
        this.getListStepPrograms();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getListStepPrograms() {
    this._stepService.getStepByProgram(this.idProgram).subscribe(
      (resp: Steps) => {
        if (resp.ok) {
          this.listSteps = resp.results;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  postSubCategory(name: string) {
    this._subCateogryService.postSubCategory(name).subscribe(
      (resp: SubCategory) => {
        if (resp.ok) {
          this.Toast.fire({
            icon: 'success',
            title: 'Se ha creado la subcategoría correctamente',
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This function updates the position of a list of steps and displays a success or error message.
   * @param {Step[]} data - An array of Step objects.
   */
  updatedPosition(data: Step[]) {
    this._stepService.updatePosition(data).subscribe(
      (res) => {
        if (res.ok) {
          this.Toast.fire({
            icon: 'success',
            title: 'Se ha actualizado la posición correctamente',
          });

          this.getListStepPrograms();
        }
      },
      (err) => {
        this.Toast.fire({
          icon: 'error',
          title: 'Ha ocurrido un error al actualizar la posición',
        });
      }
    );
  }


  openAddEventDialogMedia(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    step?: Step
  ): void {
    const dialogRef = this.dialog.open(DialogMediaComponent, {
      width: '90vw',
      height: '90vh',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        step,
      },
    });
    dialogRef.afterClosed().subscribe((data: any) => {
       if (data) {
        let newStep = {
          numberStep: this.listSteps.length + 1,
          interaction: data.interaction,
          question: data.question,
          description: data.description,
          media: data.media.id,
          idProgram: '',
        };
        if (this.idProgram) {
          newStep.idProgram = this.idProgram;
          this.newSteps.push(newStep);
          this.RegisterStep(this.newSteps, this.idProgram);
        } else {
          this.listSteps.push(newStep);
        }

      }
      this.getListStepPrograms()
    });
  }

  programEvent() {
    this.programs.value.type = this.typeForm.value;
    const subcategory = this.programs.get('Subcategory')!.value;
    if (subcategory) {
        if (!this.listCategory.find((category) => category.name === subcategory)) {
          this.postSubCategory(subcategory);
        }
    }
    if (!this.idProgram) {
      this._programsService.registerNewProgram(this.programs.value).subscribe(
        (resp: Programs) => {
          if (resp.ok) {
            this.idProgram = resp.results[0].id || '';
            this.RegisterStep(this.listSteps, this.idProgram);

            this.Toast.fire({
              icon: 'success',
              title: 'Se has creado un nuevo programa correctamente',
            });

            this.route.navigate(['programs/program_list']);
          }
        },
        (err) => {
          this.Toast.fire({
            icon: 'error',
            title: 'Ha ocurrido un error al actualizar el programa',
          });
        }
      );
    } else {
      this._programsService
        .updateProgram(this.idProgram, this.programs.value)
        .subscribe(
          (res: Programs) => {
            if (res.ok) {
              this.Toast.fire({
                icon: 'success',
                title: 'Se ha actualizado correctamente el programa',
              });
              this.route.navigate(['programs/program_list']);
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  toggleCategoryInput() {
  this.isAddingNewCategory = !this.isAddingNewCategory;
  if (this.isAddingNewCategory) {
    // Limpiar el valor de la categoría seleccionada si se va a agregar una nueva
    this.programs.get('Subcategory')?.setValue('');
  } else {
    if (this.idProgram) {
      this.programs.patchValue({
        Subcategory: this.subcategoryEl,
      });
    }
    // Limpiar el valor del campo de nueva categoría si se vuelve a seleccionar
    this.programs.get('newCategory')?.setValue('');
  }
  }

  RegisterStep(list: Step[], idProgram: string) {
    this._stepService.registerSteps(list, idProgram).subscribe(
      (res: any) => {
        if (res.ok) {
          this.Toast.fire({
            icon: 'success',
            title: 'Se ha registrado los pasos correctamente',
          });
          this.getProgramById(this.idProgram);
          this.getListStepPrograms();
          this.newSteps=[];
        }
      },
      (err) => {
        this.Toast.fire({
          icon: 'error',
          title: 'Ha ocurrido un error al registrar la media',
        });
      }
    );
  }


  deleteStep(step: Step, event:any) {
  if (step._id) {
    this._stepService.deleteStep(step._id || '').subscribe(
      (res) => {
        if (res.ok) {
          this.Toast.fire({
            icon: 'success',
            title: 'Se ha eliminado el paso correctamente',
          });
          this.getListStepPrograms();
        }
      },
      (err) => {
        this.Toast.fire({
          icon: 'error',
          title: 'Ha ocurrido un error al eliminar el paso',
        });
      }
    );
  }else{
   this.listSteps.map((item, index) => {
     if (item.numberStep === step.numberStep) {
       this.listSteps.splice(index, 1);
     }
   });
  }


  };

  private _filter(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.listTypePrograms.filter(
      (option) => option.type && option.type.toLowerCase().includes(filterValue)
    );
  }




  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listSteps, event.previousIndex, event.currentIndex);

    let newPosition: any[] = [];

    for (let index = 0; index < this.listSteps.length; index++) {
      const element = this.listSteps[index];

      let dataJson;
      if (element._id) {
        dataJson = {
          id: element._id,
          numberStep: index + 1,
        };
      } else {
        dataJson = {
          description: element.description,
          interaction: element.interaction,
          media: element.media,
          question: element.question,
          numberStep: index + 1,
        };
      }

      newPosition.push(dataJson);
    }
    if (this.listSteps.length > 1 && this.idProgram) {
      if (this.idProgram) {
        this.updatedPosition(newPosition);
      }
    }
  }
}
