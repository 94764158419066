import { Component, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { Module, Modules } from '@core/interfaces/modules.interface';
import { Permissions, Profile, Profiles } from '@core/interfaces/Profiles.interface';
import { ModulesService } from '@core/services/configurations/modules.service';
import { ProfilesService } from '@core/services/configurations/profiles.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-create-profiles',
  templateUrl: './dialog-create-profiles.component.html',
})
export class DialogCreateProfilesComponent implements OnInit, OnDestroy {
  public selectedModules: any[] = [];
  public selectedPermissions: any[] = [];
  public listModules: Module[] = [];
  public permissionsList: string[] = ['Consultar', 'Crear', 'Editar', 'Eliminar'];
  public permissionsListCreate: Permissions = {
    consultar: 'get',
    crear: 'post',
    editar: 'put',
    eliminar: 'delete'
  };

  permissionsControl = new FormControl();
  ProfileFormGroup = this.fb.group({
    code_profile: ['', Validators.required],
    name_profile: ['', Validators.required],
    list_modules: [this.selectedModules, Validators.required],
    list_permissions: this.fb.control<any>([], Validators.required)
  });

  @ViewChild('modules') modulesList: MatSelectionList | undefined;

  private modulesSubscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private profilesService: ProfilesService,
    private moduleService: ModulesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogCreateProfilesComponent>
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.ProfileFormGroup.patchValue({
        code_profile: this.data.code_profile,
        name_profile: this.data.name_profile,
        list_modules: this.data.list_modules,
        list_permissions: this.data.list_permissions
      });


      this.selectedModules = this.data.list_modules || [];
      this.ProfileFormGroup.get('list_permissions')!.setValue(this.data.list_permissions); // Para activar la detección de cambios
    }

    this.dialogRef.afterOpened().subscribe(() => {
      this.getListModules();
    });
  }

  ngOnDestroy(): void {
    if (this.modulesSubscription) {
      this.modulesSubscription.unsubscribe();
    }
  }


  getListModules(): void {
    this.modulesSubscription = this.moduleService.getListModules().subscribe(
      (resp: Modules) => {
        if (resp.ok) {
          this.listModules = resp.results;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onOptionClick(module: Module) {

    const index = this.selectedModules.findIndex(
      (selectedModule) => selectedModule._id === module.id
    );


    if (index !== -1) {
      // Si el módulo ya está seleccionado, elimínalo del array
      this.selectedModules.splice(index, 1);
    } else {
      // Si el módulo no está seleccionado, agrégalo al array
      this.selectedModules.push(module.id);
    }

  }

  eventCreateOrEdit() {
    if (this.data) {
      this.editProfile();
    } else {
      this.registerProfile();
    }
  }


  eventDialog(){
    if (this.data) {
      this.editProfile();
    } else {
      this.registerProfile();
    }
  }


  registerProfile() {
    const dataProfile: Profile = {
      code_profile: this.ProfileFormGroup.value.code_profile || '',
      name_profile: this.ProfileFormGroup.value.name_profile || '',
      list_modules: this.ProfileFormGroup.value.list_modules || [],
      list_permissions: this.ProfileFormGroup.value.list_permissions || []
    };

    if (dataProfile.list_permissions) {
      dataProfile.list_permissions = dataProfile.list_permissions.map((value) => this.permissionsListCreate[value.toLowerCase()]);
    }

    this.profilesService.createProfile(dataProfile).subscribe(
      async (resp: Profiles) => {
        if (resp.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Perfil creado correctamente',
            showConfirmButton: false,
            timer: 1500,
          });

          this.close();
        }
      },
      (err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al crear el perfil',
          text: err.error.msg,
        });
      }
    );
  }


  editProfile() {
    const profile: Profile = {
      code_profile: this.ProfileFormGroup.value.code_profile || '',
      name_profile: this.ProfileFormGroup.value.name_profile || '',
      list_modules: this.ProfileFormGroup.value.list_modules || [],
      list_permissions: this.ProfileFormGroup.value.list_permissions || []
    };

    if(profile.list_permissions) {
      profile.list_permissions = profile.list_permissions.map((value) => this.permissionsListCreate[value.toLowerCase()])
    }

    this.profilesService.updateProfile(this.data.id, profile)
      .subscribe(
        async (resp: Profiles) => {
          if (resp.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Perfil editado correctamente',
            showConfirmButton: false,
            timer: 1500,


          })
            this.close();
          }
        },
        (err) => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al editar el perfil',
            text: err.error.msg,
          });
        }
      );
  }

  isSelected(module: Module): boolean {
    return this.selectedModules.some(
      (selectedModule) => selectedModule._id === module.id
    );
  }

  close() {
    this.dialogRef.close(true);
  }

  onPermissionRemoved(permission: string) {
    const permissions = this.ProfileFormGroup.get('list_permissions')!.value as string[];
    this.removeFirst(permissions, permission);
    this.ProfileFormGroup.get('list_permissions')!.setValue(permissions);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
}
