import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsRoutingModule } from './components-routing.module';
import { SearchInputComponent } from './search-input/search-input.component';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableDataComponent } from './table-data/table-data.component';
import { CardComponent } from './card/card.component';
import { MaterialModule } from '../material.module';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { DialogComponent } from './dialog/dialog.component';


const componentsExports = [
  SearchInputComponent,
  TableDataComponent,
  CardComponent,
  DialogComponent
]

@NgModule({
  declarations: [
    componentsExports,

  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    CoreModule,
  ],
  exports: [
    componentsExports
  ]
})
export class ComponentsModule { }
