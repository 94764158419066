import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModulesComponent } from './modules.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { ModulePermissionGuard } from '@core/guards/module-perission.guard';

const routes: Routes = [

  {
    path:'',
    component: ModulesComponent,
    canActivate: [ AuthGuard ],
    children:[
     {
      path:'dashboard',
      loadChildren: () => import('./dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule),
     },
     {
      path:'participants',
      loadChildren: () => import('./participants/participants-routing.module').then(m => m.ParticipantsRoutingModule),
      canActivateChild: [ModulePermissionGuard]
     },
     {
      path:'lines',
      loadChildren: () => import('./lines-groups/lines-groups-routing.module').then(m => m.LinesGroupsRoutingModule),
      canActivateChild: [ModulePermissionGuard]

     },
     {
      path:'programs',
      loadChildren: () => import('./programs/programs-routing.module').then(m => m.ProgramsRoutingModule),
      canActivateChild: [ModulePermissionGuard]

     },
     {
      path: 'configurations',
      loadChildren: () => import('./configurations/configurations-routing.module').then(m => m.ConfigurationsRoutingModule),
      canActivateChild: [ModulePermissionGuard]

     }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModulesRoutingModule { }
