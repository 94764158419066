<div class="tagus-search-box search-input">

  <input type="text" class="input-search border-radius" (keyup)=" sendSearch($event)" placeholder="Search here">
  <button type="submit"><i class="ri-search-line"></i></button>
</div>
<br>

  <form  [formGroup]="filtersForm" class="row tagus-form-group">
  <ng-container *ngFor="let filterConfig of filterConfigs">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <div class="note-box">
          <mat-form-field appearance="fill">
            <i class="{{ filterConfig.icon }}"></i>
            <mat-label>{{ filterConfig.name }}</mat-label>
            <mat-select [formControlName]="filterConfig.name" (onselectionChange)="applyFilters()">
              <mat-option disabled>Selecciona</mat-option>
              <mat-option [value]="null">Ninguno</mat-option>
              <mat-option *ngFor="let option of filterConfig.options" [value]="option">{{  filterConfig.pipe === "state" ? (option | stateTransform) : option }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </form>

