<app-card title="Listado de Programas">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="tagus-search-box search-input mb-20">

      <input
        matInput
        type="text"
        [formControl]="myControl"
        [matAutocomplete]="auto"
        class="input-search border-radius"
        placeholder="Search here">
      <button type="submit"><i class="ri-search-line"></i></button>
    </div>
    <div class="tagus-form-group">

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
          (onSelectionChange)="optionSelected(option)"
        >
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>

  <div class="teamMembersListTable tagus-mat-table mat-elevation-z8">
    <mat-tab-group>
      <mat-tab
        label="{{ info.group.name }}"
        *ngFor="let info of dataGroup; let i = index"

      >

        <mat-accordion class="tagus-advance-expansion">
          <mat-expansion-panel
            *ngFor="let item of info.levels; let j = index"
            [expanded]="isPanelExpanded(i, j)"
            (opened)="panelOpened(i, j)"
            (closed)="panelClosed(i, j)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong class="subtitle-card-color">Programa {{ j + 1 }}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <app-diagram
                [dataLevels]="item"
                [Match]="info"
                (reloadData)="changeInfo($event)"
              ></app-diagram>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="mb-20 mt-20 text-end">
              <button
                class="default-btn bg-main-color-orange-button"
                (click)="addNewFirstLevel(info, info.levels)"
              >
                <i class="ri-add-fill"></i> Agregar primer programa
              </button>
            </div>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>
  </div>
</app-card>
