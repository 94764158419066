import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubCategory, SubCateogries } from '@core/interfaces/subCategoriesProgram.interface';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubCategoriesProgramsService {

  public urlBackend: string = environment.base_url;
  public userData: any;
  public headers: any;
  public tokenUser: string = '';

  constructor(private http: HttpClient) {


    this.headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization',`Bearer ${localStorage.getItem('token_conhector') || ''}`)
    .set('idUser', localStorage.getItem('user_conhector') || ' ');
   }


   getListSubCategory(): Observable<SubCateogries> {
    return this.http
      .get<SubCateogries>(`${this.urlBackend}/configurations/subCategoryPrograms`, {
        headers: this.headers,
      })
      . pipe(
        catchError((error: HttpErrorResponse) => {
        let errorMessage =
          'Ocurrió un error en la solicitud de las project list';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de getListSubCategory: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(error);
      })
      )
  }

  postSubCategory(name: string): Observable<SubCategory> {
    return this.http
      .post<SubCategory>(`${this.urlBackend}/configurations/subCategoryPrograms`,{name: name} ,{
        headers: this.headers,
      })
      . pipe(
        catchError((error: HttpErrorResponse) => {
        let errorMessage =
          'Ocurrió un error en la solicitud de las project list';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de getListSubCategory: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(error);
      })
      )
  }

}
