import { Component } from '@angular/core';

@Component({
  selector: 'app-construction-site',
  templateUrl: './construction-site.component.html',
  styleUrls: ['./construction-site.component.scss']
})
export class ConstructionSiteComponent {

}
