import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { reset_password } from '@core/interfaces/auth.interface';
import { AuthService } from '@core/services/authentication/auth.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  hide = true;

  public hidePassword_actual = true;
  queryParams: any;
  previousPassword = new FormControl('', Validators.required);
  newPassword = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
  confirmPassword = new FormControl('', Validators.required);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _authService: AuthService,
    public themeService: CustomizerSettingsService
  ) {}


  ngOnInit(): void{
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
  }



  public resetPassword(): void {
    let resetJson: reset_password = {
      token: this.queryParams.token || '',
      password: this.previousPassword.value || '',
      newPassword: this.newPassword.value || '',
    };

    if (this.newPassword.value === this.confirmPassword.value) {
      this._authService.resetPassword(resetJson).subscribe(
        (res) => {

          Swal.fire({
            title: '¡Contraseña cambiada!',
            text: 'Tu contraseña ha sido cambiada correctamente.',
            icon: 'success',
          });

          this.router.navigateByUrl('/login');
        },
        (err) => {
          Swal.fire({
            title: 'Upps!',
            text: 'Ocurrió un error al cambiar tu contraseña, por favor intenta de nuevo.',
            icon: 'error',
          });
        }
      );
    } else {
      Swal.fire({
        title: 'Upps!',
        text: 'Las contraseñas no coinciden, por favor verifica que sean iguales.',
        icon: 'warning',
      });
    }
  }

  toggleTheme() {
      this.themeService.toggleTheme();
  }

  toggleCardBorderTheme() {
      this.themeService.toggleCardBorderTheme();
  }

  toggleCardBorderRadiusTheme() {
      this.themeService.toggleCardBorderRadiusTheme();
  }

  toggleRTLEnabledTheme() {
      this.themeService.toggleRTLEnabledTheme();
  }

}
