import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Line, Lines } from '@core/interfaces/lines.interface';
import { LinesGroupsService } from '@core/services/linesGroups/lines-groups.service';

@Component({
  selector: 'app-lists-line',
  templateUrl: './lists-line.component.html',
  styleUrls: ['./lists-line.component.scss'],
})
export class ListsLineComponent implements OnInit {
  /** Evento de cambio de página. */
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  /** Número de página actual. */
  public pageIndex: number = 1;
  /** Tamaño de la página actual. */
  public pageSize: number = 10;
  /** Número total de elementos. */
  public totalItems?: number;

  public titleButton = 'Crear Línea';

  displayedColumns: string[] = ['name', 'indicative', 'action'];

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  public listLine: Line[] = [];
  dataSource = new MatTableDataSource<Line>(this.listLine);

  active = true;
  inactive = true;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public _linesGroupsService: LinesGroupsService
  ) {}

  ngOnInit(): void {
    this.getListLines();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe((event: PageEvent) => {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getListLines();
      })
    }
  }


  getListLines(): void {
    this._linesGroupsService.getListLinesPaginate(this.pageIndex, this.pageSize,).subscribe(
      (resp: any) => {
        if (resp.ok) {
          this.listLine = resp.results.data;
          this.dataSource.data = this.listLine;
          this.totalItems = resp.results.pagination.total;
          this.dataSource = new MatTableDataSource(this.listLine);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }



  editLine(data: any): void {
    this.router.navigate(['lines/edit-line', data._id]);
  }

  /**
   * Maneja el cambio de página del paginador.
   * @param event Evento de cambio de página.
   */
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageChange.emit({ pageIndex: this.pageIndex, pageSize: this.pageSize });
    this.getListLines();
  }
}
