<mat-card class="profile-card mb-25 tagus-card">
  <mat-card-header class="border-bottom">
    <h5 class="mb-0 tertiary-blue-color">Gestión de Mi Perfil</h5>
  </mat-card-header>
  <mat-card-content>
    <form autocomplete="off" [formGroup]="userForm" (ngSubmit)="updateUser()" id="userForm">
      <div class="row align-items-center">
        <div class="col-lg-2 col-md-2 col-sm-12 text-center">
          <div class="profile-picture-wrapper">
            <div class="profile-picture-container">
              <img *ngIf="!croppedImage && infoUser.img" [src]="infoUser.img" alt="Profile" class="profile-image">
              <img *ngIf="croppedImage" [src]="croppedImage" alt="Profile" class="profile-image">
              <mat-icon class="camera-icon">photo_camera</mat-icon>
            </div>
          </div>
        </div>

        <div class="col-lg-7 col-md-7 col-sm-6 text-center">
          <div class="profile-picture-container">
            <mat-label class="btn-subir subtitle-card-color">Cambiar imagen de perfil</mat-label>

            <label class="profile-dropzone">
              <label class="subtitle-card-color">
                Arrastra y suelta archivos o click aquí para seleccionar.
              </label>
              <input type="file" (change)="onFileChange($event)" class="profile-dropzone">
            </label>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-6 text-center">
          <button mat-raised-button class="btn-subir bg-main-color-blue" (click)="uploadImgPerfil()">
            <mat-icon class="upload-icon">upload</mat-icon> Subir
          </button>
        </div>
      </div>
      <div class="row perfil-info">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="tagus-form-group">
            <label class="d-block mb-12 fw-semibold subtitle-card-color">Nombre</label>
            <mat-form-field appearance="fill">
              <i class="ri-user-3-line"></i>
              <input matInput formControlName="name" required autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="tagus-form-group">
              <label class="d-block mb-12 fw-semibold subtitle-card-color">Apellido(s)</label>
              <mat-form-field appearance="fill">
                  <i class="ri-user-3-line"></i>
                  <input matInput formControlName="last_name" required autocomplete="off">
              </mat-form-field>
          </div>
      </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="tagus-form-group">
            <label class="d-block mb-12 fw-semibold subtitle-card-color">Correo</label>
            <mat-form-field appearance="fill">
              <i class="ri-inbox-2-fill"></i>
              <input matInput formControlName="email" required autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="tagus-form-group">
              <label class="d-block mb-12 fw-semibold subtitle-card-color">Celular</label>
              <mat-form-field appearance="fill">
                  <i class="ri-mail-line"></i>
                  <input matInput formControlName="phone" type="text" name="phone" [value]="userForm.value.phone ?? '' | formatPhone" required >
              </mat-form-field>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="tagus-form-group">
              <label class="d-block mb-12 fw-semibold subtitle-card-color">Project</label>
              <mat-form-field appearance="fill">
                <i class="ri-projector-line"></i>
                <input matInput [value]="userForm.value.project" disabled>
              </mat-form-field>
          </div>
      </div>
      </div>
      <div class="row mt-20">
        <div class="col-12 text-right">
          <button mat-button class="cancel-button bg-main-color-orange-button" [routerLink]="'/'">Cancelar</button>
          <button mat-raised-button class="tagus fw-semibold bg-main-color-blue" (click)="updateUser()">Actualizar datos</button>
        </div>
      </div>
    </form>
    <div class="d-flex align-items-center justify-content-space-between">
      <a routerLink="/forgot-password" class="d-inline-block tertiary-blue-color fw-semibold" (click)="forgotPassword()">Cambiar contraseña</a>
    </div>
  </mat-card-content>
</mat-card>
