import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { UsersComponent } from './users/users.component';
import { ModulesProfilesComponent } from './modules/modulesProfiles.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data:{
      firstBreadcrumb: {
        title: 'Configuración CMS',
        icon: '../../../assets/img/icons/configurations.svg',
        url: ''
      },
      title: 'Listado de perfiles',
      module: 'profile',
      section: 'configuraciones',

    }
  },
  {
    path:'users',
    component: UsersComponent,
    data:{
      firstBreadcrumb: {
        title: 'Configuración CMS',
        icon: '../../../assets/img/icons/configurations.svg',
        url: ''
      },
      title: 'Listado de usuarios',
      module: 'users',
      section: 'configuraciones',
    }
  },
  {
    path:'modules',
    component: ModulesProfilesComponent,
    data:{
      firstBreadcrumb: {
        title: 'Configuración CMS',
        icon: '../../../assets/img/icons/configurations.svg',
        url: ''
      },
      title: 'Listado de perfiles',
      url: '',
      icon: 'list',

    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class ConfigurationsRoutingModule { }
