import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinesGroupsRoutingModule } from './lines-groups-routing.module';
import { ListsLineComponent } from './pages/lists-line/lists-line.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CreateEditLineComponent } from './pages/create-edit-line/create-edit-line.component';
import { DialogGroupBox } from './components/dialog-group.component';
import { CoreModule } from '@core/core.module';
import { SpinnerInterceptor } from '@core/interceptors/spinner.interceptor';
import { SharedModule } from '@shared/shared.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { QRCodeModule } from "angularx-qrcode";
import { LinesCampainsComponent } from './pages/lines-campains/lines-campains.component'
import { ColorPickerModule } from 'ngx-color-picker';
import { DialogViewCampaignComponent } from './pages/dialog-view-campaign/dialog-view-campaign.component';
import { LinePhoneView } from './components/line-phone-view/line-phone-view.component';


@NgModule({
  declarations: [
    ListsLineComponent,
    CreateEditLineComponent,
    LinePhoneView,
    DialogGroupBox,
    LinesCampainsComponent,
    DialogViewCampaignComponent,
  ],
  imports: [
    CommonModule,
    LinesGroupsRoutingModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    ComponentsModule,
    NgxDropzoneModule,
    QRCodeModule,
    ColorPickerModule
  ],providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true}
  ]
})
export class LinesGroupsModule { }
