<app-breadcrumbs *ngIf="breadCrumb"></app-breadcrumbs>

<mat-card class="mb-25 tagus-card"
[class.component-dark-theme]="themeService.isDark()"
[class.component-rtl-enabled]="themeService.isRTLEnabled()">
  <mat-card-header>
    <h5 class="mb-0 title-color">{{ title }}</h5>
    <a mat-flat-button class="default-btn bg-color-blue-button" [routerLink]="link" *ngIf="link" routerLinkActive="active">
      <i class="ri-add-fill"></i> {{titleRouter}}
    </a>

    <button mat-flat-button class="default-btn bg-color-blue-button" (click)="actionEvent()" *ngIf="titleButton" routerLinkActive="active"><i class="ri-add-fill"></i>  {{titleButton}}</button>

  </mat-card-header>
  <mat-card-content>
    <div class="teamMembersListTable tagus-mat-table mat-elevation-z8">
      <ng-content></ng-content>
    </div>
  </mat-card-content>
</mat-card>
