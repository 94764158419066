import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Medias } from '@core/interfaces/media.interface';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MultimediasService {
  public urlBackend: string = environment.base_url;
  public headers: any;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Authorization', `Bearer ${localStorage.getItem('token_conhector')}`)
      .set('idUser', localStorage.getItem('user_conhector') || '');
  }

  getMediaById(id: string): Observable<Medias> {
    return this.http
      .get<Medias>(`${this.urlBackend}/globales/uploads/${id}`, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListMedia: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  getListMedia(page: number, limit: number, searchValue: string = ''): Observable<any> {
    let params = new HttpParams()
    .set('page', page.toString())
    .set('limit', limit.toString());

    if (searchValue) {
      params = params.set('searchValue', searchValue);
    }
    return this.http
      .get<Medias>(`${this.urlBackend}/globales/uploads`, {
        headers: this.headers,
        params: params // Pasar los parámetros de paginación en la solicitud GET
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las líneas';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de getListMedia: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(errorMessage);
        })
      );
  }

  sendPost(dtForm: any): Observable<Medias> {
    if (this.isFormDataEmpty(dtForm)) {
      console.log('El FormData está vacío');
    } else {
      console.log('El FormData no está vacío');
    }
    return this.http
      .post<Medias>(`${this.urlBackend}/globales/upload`, dtForm, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las medias';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de sendPost: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  isFormDataEmpty(formData: FormData): boolean {
    const fields = formData.getAll('');

    // Verifica si la longitud de los campos es cero
    return fields.length === 0;
  }

  cargarMedia(media: any): Observable<Medias> {

    return this.http
      .post<Medias>(`${this.urlBackend}/globales/upload`, media, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las medias';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de cargarMedia: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  guardarLinkVideo(data: any): Observable<any> {
    return this.http
      .post(`${this.urlBackend}/globales/upload/video`, data, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las medias';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de cargarMedia: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }
}
