import { Component } from '@angular/core';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';

@Component({
  selector: 'app-interactions-msg',
  templateUrl: './interactions-msg.component.html',
  styleUrls: ['./interactions-msg.component.scss']
})
export class InteractionsMsgComponent {
 public title: string = 'Mensajes';
 constructor(
  public themeService: CustomizerSettingsService
) {}

toggleTheme() {
  this.themeService.toggleTheme();
}

toggleRTLEnabledTheme() {
  this.themeService.toggleRTLEnabledTheme();
}

}
