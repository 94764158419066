import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';

import { User, Users } from '@core/interfaces/User.interface';
import { ToggleService } from 'src/app/core/services/toggle.service';
import { UsersService } from '@core/services/configurations/users.service';
import { CustomizerSettingsService } from 'src/app/core/services/customizer-settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public userInfo: User = {};

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  isToggled = false;

  constructor(
    public router: Router,
    private datePipe: DatePipe,
    private _userService: UsersService,
    private toggleService: ToggleService,
    public themeService: CustomizerSettingsService
  ) {
    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
  }

  ngOnInit(): void {
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggle() {
    this.toggleService.toggle();
  }

  currentDate: Date = new Date();
  formattedDate: any = this.datePipe.transform(
    this.currentDate,
    'dd MMMM yyyy',
    'es'
  );


}
