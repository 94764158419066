import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProfileComponent } from './profile/profile.component';
import { ConfigurationsRoutingModule } from './configurations-routing.module';

import { CoreModule } from '@core/core.module';
import { MaterialModule } from 'src/app/material.module';
import { UsersComponent } from './users/users.component';
import { CreateEditUserComponent } from './dialog/create-edit-user/create-edit-user.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ModulesProfilesComponent } from './modules/modulesProfiles.component';
import { DialogCreateProfilesComponent } from './dialog/dialog-create-profiles/dialog-create-profiles.component';
import { DialogListUserProfilesComponent } from './dialog/dialog-list-user-profiles/dialog-list-user-profiles.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { DialogImageCropperComponent } from './dialog/dialog-image-cropper/dialog-image-cropper.component';

@NgModule({
  declarations: [
    ProfileComponent,
    UsersComponent,
    CreateEditUserComponent,
    ModulesProfilesComponent,
    DialogCreateProfilesComponent,
    DialogListUserProfilesComponent,
    DialogImageCropperComponent,
  ],
  imports: [
    CommonModule,
    ConfigurationsRoutingModule,
    CommonModule,
    MaterialModule,
    NgxDropzoneModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule
  ]
})
export class ConfigurationsModule { }
