import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { CommonModule } from '@angular/common';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkAccordionModule } from '@angular/cdk/accordion';

import { ProgramsRoutingModule } from './programs-routing.module';
import { ListMatchComponent } from './pages/list-match/list-match.component';
import { DialogMediaComponent } from './components/dialog-media/dialog-media.component';
import { ListProgramsComponent } from './pages/list-programs/list-programs.component';
import { CreateEditProgramsComponent } from './pages/create-edit-programs/create-edit-programs.component';
import { SpinnerInterceptor } from '@core/interceptors/spinner.interceptor';
import { DialogAddLevelComponent } from './components/dialog-add-level/dialog-add-level.component';
import { DiagramComponent } from './components/diagram/diagram.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { PrimeNgModule } from 'src/app/primeng.module';
import { DialogViewProgramComponent } from './components/dialog-view-program/dialog-view-program.component';


@NgModule({
  declarations: [
    ListProgramsComponent,
    ListMatchComponent,
    CreateEditProgramsComponent,
    DialogMediaComponent,
    DialogAddLevelComponent,
    DialogViewProgramComponent,
    DiagramComponent
  ],
  imports: [
    CommonModule,
    ProgramsRoutingModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    NgxDropzoneModule,
    ComponentsModule,
    PrimeNgModule,
    CdkAccordionModule
  ],providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true}
  ]
})
export class ProgramsModule { }
