import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() title: string = '';
  @Input() link: string = '';
  @Input() titleRouter: string = '';

  @Input() titleButton: string = '';
  @Input() breadCrumb: boolean =true;

  @Output() action: any  = new EventEmitter<string>();

  constructor(  public themeService: CustomizerSettingsService,) { }



  actionEvent(){
  this.action.emit();
  }
}
