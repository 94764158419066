<div class="table-container">
  <app-search (filterEvent)="applyFilter($event)"></app-search>
  <div class="table-wrapper">
    <table mat-table [dataSource]="dataSource" class="table-responsive scrollable-table">
      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef class="sticky-header">{{ column }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!element.isAddRow; else addRowTemplate">
            <ng-container *ngIf="column !== 'action'; else actionCell">
              <ng-container *ngIf="column !== 'img' && column !== 'fileUrl'; else imgCell">
                <ng-container *ngIf="column !== 'state'; else activated">
                  <ng-container *ngIf="!isEditMode(element) || column === 'id'">
                    <span matTooltip="{{ element[column] }}" matTooltipPosition="above" *ngIf="element[column]">
                      {{ element[column] | truncate:20 }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="isEditMode(element) && column !== 'id'">
                    <input class="inputEdit" matInput [(ngModel)]="element[column]" />
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #activated>
              <ng-container *ngIf="element.state === 'A'; else inactiveOrExpiredTemplate">
                <span class="badgea fw-medium">Activo</span>
              </ng-container>

              <ng-template #inactiveOrExpiredTemplate>
                <ng-container *ngIf="element.state === 'I'; else expiredTemplate">
                  <span class="badgea inactived fw-medium">Inactivo</span>
                </ng-container>
              </ng-template>

              <ng-template #expiredTemplate>
                <span class="badgea pending fw-medium">Expirado</span>
              </ng-template>
            </ng-template>

            <ng-template #imgCell>
              <img [src]="element[column]" alt="imagen" width="50" height="50" />
            </ng-template>
            <ng-template #actionCell>
              <div *ngIf="multipleChoice; else radioButtonTemplate">
                <mat-checkbox [checked]="element.selected" (change)="ActionButton('checkbox', element)">
                </mat-checkbox>
              </div>
              <ng-template #radioButtonTemplate>
                <div *ngIf="radioButton; else buttonEvent">
                  <mat-radio-button [value]="element" (change)="ActionButton('radioButton',$event)"></mat-radio-button>
                </div>
              </ng-template>
              <ng-template #buttonEvent class="example-buttons">
                <div *ngIf="!isEditMode(element); else editModeButtons">
                  <button mat-icon-button class="blue-color" *ngIf="selectButton" matTooltip="Seleccionar" (click)="ActionButton('select', element)">
                    <mat-icon class="ri-save-line"></mat-icon>
                  </button>
                  <button mat-icon-button class="blue-color" *ngIf="viewButton" matTooltip="Ver" (click)="ActionButton('view', element)">
                    <mat-icon class="ri-eye-fill"></mat-icon>
                  </button>
                  <button mat-icon-button class="blue-color" *ngIf="editButton" matTooltip="Editar" (click)="startEdit(element)">
                    <mat-icon class="ri-edit-line"></mat-icon>
                  </button>
                  <button mat-icon-button class="blue-color" *ngIf="stateButton" matTooltip="Cambiar estado" (click)="ActionButton('state', element)">
                    <mat-icon class="ri-refresh-line"></mat-icon>
                  </button>
                  <button mat-icon-button class="blue-color" *ngIf="codeqrButton" (click)="ActionButton('qrcode', element)"
                  matTooltip="Campaña">
                  <mat-icon class="ri-qr-code-line"></mat-icon>
                </button>
                  <button mat-icon-button class="blue-color" *ngIf="deleteButton" matTooltip="Eliminar" (click)="ActionButton('delete', element)">
                    <mat-icon class="ri-delete-bin-line"></mat-icon>
                  </button>
                </div>
                <ng-template #editModeButtons>
                  <button mat-icon-button class="blue-color" (click)="saveEdit(element)">
                    <mat-icon>check</mat-icon>
                  </button>
                  <button mat-icon-button class="blue-color" (click)="cancelEdit(element)">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </ng-template>
              </ng-template>
            </ng-template>
          </ng-container>

          <ng-template #addRowTemplate>
            <ng-container *ngIf="column !== 'action'">
              <input matInput disabled class="inputSkeleton" />
            </ng-container>
            <ng-container *ngIf="column === 'action'">
              <button mat-button class="addButton" matTooltip="Agregar" (click)="addRow()">
                <i class="ri-add-circle-line"></i> Agregar
              </button>
            </ng-container>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
      <!-- Fila de agregar -->
      <tr class="add-row" *ngIf="showAddRow">
        <td *ngFor="let column of columns">
          <ng-container *ngIf="column !== 'action'; else actionCellAdd">
            <input matInput disabled class="inputSkeleton" />
          </ng-container>
          <ng-template #actionCellAdd>
            <button mat-button class="addButton" matTooltip="Agregar" (click)="addRow()">
              <i class="ri-add-circle-line"></i> Agregar
            </button>
          </ng-template>
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons [pageSize]="pageSize" [length]="totalItems" (page)="onPageChange($event)">
  </mat-paginator>
</div>
