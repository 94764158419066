import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@core/interfaces/modules.interface';
import { User, Users } from '@core/interfaces/User.interface';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public urlBackend: string = environment.base_url;
  public headers: any;

  constructor(private http: HttpClient) {
    this.headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token_conhector') || ''}`,
      idUser: localStorage.getItem('user_conhector') || ' ',
    });
  }

  getInfoUser(id :string): Observable<Users> {
    return this.http.get<Users>(`${this.urlBackend}/configurations/user/${id}`, {
      headers: this.headers,
    }). pipe(
      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las project list';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de getInfoUser: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    })
    );
  }


  createUser(user: User):Observable<User>{

    return this.http.post<User>(`${this.urlBackend}/configurations/user`, user, {
      headers: this.headers,
    }). pipe(
      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las project list';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de createUser: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    })
    );


  }


  UpdateUser(id: string, user: User): Observable<Users> {
    return this.http.put<Users>(`${this.urlBackend}/configurations/user/${id}`, user, {
      headers: this.headers,
    }). pipe(
      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las project list';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de UpdateUser: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    })
    );
  }


  registerProfileUser(id: string, profile: string): Observable<User> {

    let params = new HttpParams()
    .set('id', id)
    .set('profile', profile);


    return this.http.get<User>(`${this.urlBackend}/configurations/user/addProfile`, {
      params: params,
      headers: this.headers,
    }). pipe(
      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las project list';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de registerProfileUser: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    })
    );
  }


  filterProfileUser(profile: string): Observable<Users> {

    let params = new HttpParams()
    .set('profile', profile);

    return this.http.get<Users>(`${this.urlBackend}/configurations/user/filterProfile`, {
      params: params,
      headers: this.headers,
    }). pipe(
      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las project list';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de filterProfileUser: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    })
    );
  }

  changeStatusUser(id: string): Observable<User> {

    return this.http.get<User>(`${this.urlBackend}/configurations/user/changeStatus/${id}`, {
      headers: this.headers
    }).pipe(
      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las project list';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de changeStatusUser: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    })

    ) ;
  }

  deleteUser(id: string ): Observable<any> {

    return this.http.delete(`${this.urlBackend}/configurations/user/${id}`, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud deleteUser';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de deleteUser: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }




}
