import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Program, Programs, ProgramsFilter } from '@core/interfaces/Programs.interface';
import { FilterConfig } from '@core/interfaces/filters.interface';
import { FilterService } from '@core/services/filters/filter.service';
import { MatchService } from '@core/services/programs/match.service';
import { ProgramsService } from '@core/services/programs/programs.service';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-add-level',
  templateUrl: './dialog-add-level.component.html',
  styleUrls: ['./dialog-add-level.component.scss'],
})
export class DialogAddLevelComponent implements OnInit, OnDestroy{

  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  public loadData = new EventEmitter<any>();
  /** Paginator de la tabla. */
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  displayedColumns: string[] = ['program_name','type','Subcategory','menu_option','action'];
  public listPrograms: any[] = [];
  public listProgramsToSave: Programs[] = []
  /** Número total de elementos. */
  public totalItems?: number;
  public pageSize: number = 10;
  public pageIndex = 1;
  /*  La configuracion de botones de filtrado. */
  filterConfigs: FilterConfig[] = [];

  /** Subscripcion al la eleccion del filtrado */
  private filterSubscription: Subscription | undefined;
  /** Respuesta del servicio con lso filtros escogidos*/
  public dataFilters: ProgramsFilter = {};

  public match: any;
  public level: number = 0;
  public position: string = '';
  public program: any;

  public dataFilterPosition: any;
  public typeEvent = '';
  public multiChoise: boolean = false;

  myControl = new FormControl<string | any>('');
  filteredOptions!: Observable<any[]>;
  dataSource = new MatTableDataSource<Program>(this.listPrograms);

  constructor(
    private programsService: ProgramsService,
    private matchService: MatchService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAddLevelComponent>,
    private filterService: FilterService,
  ) {
    if (data) {
      this.match = data.Match;
      this.level = data.level;
      this.position = data.position;
      this.typeEvent = data.isUpdate;
      this.multiChoise = data?.multipleChoise;

      if (this.typeEvent !== 'returnsId') {
        this.searchLevelByPosition();
      }
    }
  }

  ngOnInit(): void {
    // Obtener configuración de filtros al iniciar
    this.programsService.getListFiltersPrograms().subscribe((resp: any) => {
      const types: string[] = Object.keys(resp.filters);

      // Configuración de los filtros
      this.filterConfigs = [
        {
          name: 'Tipo',
          icon: 'ri-list-check-2',
          options: types,
          dbName: 'type',
        },
        {
          name: 'Categoria',
          icon: 'ri-bar-chart-horizontal-fill',
          options: [],
          dbName: 'Subcategory',
        },
        {
          name: 'Estado',
          icon: 'ri-bard-line',
          options: [],
          dbName: 'state',
          pipe: 'state',
        },
      ];

      // Pasar la configuración de filtros al servicio
      this.filterService.setFilterConfigs(this.filterConfigs, resp.filters);
    });

    // Suscripción a los cambios de filtros
    this.filterSubscription = this.filterService.filter$.subscribe((filters) => {
      if (filters.type) {
        const selectedCategoryData = this.filterService.getFilterDataByCategory(filters.type);
        if (selectedCategoryData) {
          this.filterConfigs.find((config) => config.name === 'Categoria')!.options = selectedCategoryData.subcategories;
          this.filterConfigs.find((config) => config.name === 'Estado')!.options = selectedCategoryData.states;
        }
      }

      // Obtener la lista de programas paginada con los filtros aplicados
      this.getListProgramsPaginate(filters);
      this.dataFilters = filters;
    });

    // Obtener la lista inicial de programas paginada
    this.getListProgramsPaginate();
  }


  /** Obtiene la lista de programas con paginación. */
  getListProgramsPaginate(filters?: any) {
    this.programsService.getListProgramsPaginate(this.pageIndex, this.pageSize, filters).subscribe(
      (resp: any) => {
        if (resp.ok) {
          this.listPrograms = resp.results.data;
          this.dataSource.data = this.listPrograms;
          this.totalItems = resp.results.pagination.total;
          this.dataSource = new MatTableDataSource(this.listPrograms);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy(): void {
    /* Se destruye la subscripción al servicio de filtrado */
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  viewCheckedPrograms (programs: any) {
    this.listProgramsToSave = [...programs];
  }

  async saveMultiplePrograms() {
    let listUpdate = [];
    for (const program of this.listProgramsToSave) {
      this.program = program;
      switch (this.typeEvent) {
        case 'update':
          const matchUpdate = await this.updateLevel();
          listUpdate.push(matchUpdate)
          console.log('Actualizando niveles:', matchUpdate);

          break;

        case 'notUpdate':
          const match = await this.registerNewLevel();
          listUpdate.push(match)
          console.log('Agregando niveles:', match);
          break;

        case 'returnsId':
          await this.registerNewFirstLevel();
          console.log('Registrando primer nivel');

          break;

        default:
          console.log('Tipo de evento no reconocido');
      }
    }
    this.close(listUpdate)
  }


  toggleProgramSelection(program: any): void {
    this.program = program;
    this.typeEventService().then((res) => {
      this.close(res);
    }).catch(err => {
      console.error('Error during typeEventService execution:', err);
    });
  }

  registerNewLevel(): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(this.dataFilterPosition)
      let childrenLength = 1;
      if (this.dataFilterPosition.children) {
        // Calcula la longitud basada en los hijos existentes
        childrenLength = this.dataFilterPosition.children.length + 1;
      } else {
        // Inicializa el array de children si no existe
        this.dataFilterPosition.children = [];
      }

      // Siempre incrementa la longitud de children para reflejar el nuevo hijo que se agregará
      this.dataFilterPosition.children.push({});

      let dataRegister = {
        level: this.dataFilterPosition.level,
        position: this.dataFilterPosition.position,
        data: {
          level: this.dataFilterPosition.level + 1,
          position: `${this.dataFilterPosition.position}.${childrenLength}`,
          program: this.program._id,
        },
      };

      this.matchService.registerNewLevel(this.match.id, dataRegister).subscribe(
        (res) => {
          if (res.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Se ha registrado el nuevo Match',
              iconHtml: '<i class="ri-check-line" style="color: green;"></i>',
              customClass: {
                popup: 'swal-custom-popup',
                title: 'swal-custom-title',
                htmlContainer: 'swal-custom-content'
              },
              showConfirmButton: false,
              timer: 3000,
              backdrop: false,
              toast: true,
              position: 'top-end',
              background: '#ffffff',
              color: '#1d2c4d',
              padding: '1rem',
              width: '20rem',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            });

            resolve( {method: 'update', response: res.match} );
          } else {
            reject( 'Registration failed' );
          }
        },
        (err) => {
          console.log(err);
          Swal.fire('Se ha presentado un error al registrar el match', '', 'error');
          reject(err);
        }
      );
    });
  }


  updateLevel(): Promise<any> {
    return new Promise((resolve, reject) => {
      let dataJson = {
        level: this.level,
        position: this.position,
        program: this.program._id,
      };

      this.matchService.updateProgram(this.match.id, dataJson).subscribe(
        (res: any) => {
          if (res.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Se ha actualizado el programa',
              iconHtml: '<i class="ri-check-line" style="color: green;"></i>',
              customClass: {
                popup: 'swal-custom-popup',
                title: 'swal-custom-title',
                htmlContainer: 'swal-custom-content'
              },
              showConfirmButton: false,
              timer: 3000,
              backdrop: false,
              toast: true,
              position: 'top-end',
              background: '#ffffff',
              color: '#1d2c4d',
              padding: '1rem',
              width: '30rem',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            });
            resolve( {method: 'update', response: res.match} );
          } else {
            reject('Update failed');
          }
        },
        (err: any) => {
          console.log(err);
          Swal.fire('Se ha presentado un error al registrar el match', '', 'error');
          reject(err);
        }
      );
    });
  }


  searchLevelByPosition() {
    let data = {
      targetPosition: this.position,
      targetLevel: this.level,
      levels: this.match?.levels,
    };

    this.matchService.searchLevelAndPosition(data).subscribe(
      (res: any) => {

        if (res.ok) {
          this.dataFilterPosition = res.results;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  onPageChange(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageChange.emit({ pageIndex: this.pageIndex, pageSize: this.pageSize });
    this.getListProgramsPaginate(this.dataFilters);
  }

  async typeEventService(): Promise<void> {
    switch (this.typeEvent) {
      case 'update':
        const matchUpdate = await this.updateLevel();
        return matchUpdate;
      case 'notUpdate':
        const match = await this.registerNewLevel();
        return match;

      case 'returnsId':
        await this.registerNewFirstLevel();
        break;
    }
  }

  registerNewFirstLevel(): Promise<void> {
    return new Promise((resolve, reject) => {
      let idMatch = this.data.body.match;
      console.log("data", this.data)

      let infoBody = {
        level: this.data.body.level,
        position: this.data.body.position,
        program: this.program._id,
        children: [],
      };

      this.matchService.addLevelFrist(idMatch, infoBody).subscribe(
        (res: any) => {
          if (res.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Se ha registrado el nuevo Match',
              iconHtml: '<i class="ri-check-line" style="color: green;"></i>',
              customClass: {
                popup: 'swal-custom-popup',
                title: 'swal-custom-title',
                htmlContainer: 'swal-custom-content'
              },
              showConfirmButton: false,
              timer: 3000,
              backdrop: false,
              toast: true,
              position: 'top-end',
              background: '#ffffff',
              color: '#1d2c4d',
              padding: '1rem',
              width: '20rem',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            });
            this.data.body.position = +res?.result?.levels[res?.result?.levels.length -1].position + 1;
            resolve();
          } else {
            reject('First level registration failed');
          }
        },
        (err: any) => {
          console.log(err);
          Swal.fire('Se ha presentado un error al registrar el match', '', 'error');
          reject(err);
        }
      );
    });
  }

  close(res?: any): void {
    this.dialogRef.close(res);
  }

}
