import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MatchService {
  public url: string = environment.base_url;
  public userData: any;
  public headers: any;
  public tokenUser: string = '';
  constructor(private http: HttpClient) {
    this.headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token_conhector') || ''}`,
      idUser: localStorage.getItem('user_conhector') || ' ',
    });
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occurred', error);
    return throwError(error);
  }

  getListMatchByIdLine(id: string): Observable<any> {
    return this.http
      .get(`${this.url}/match/filterByLine/${id}`, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  registerNewLevel(id: string, data: any): Observable<any> {

    return this.http
      .post(`${this.url}/match/addLevel/${id}`, data, {
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  updateProgram(idMatch: string, data: any): Observable<any> {
    return this.http
      .put(`${this.url}/match/updateProgram/${idMatch}`, data, {
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  addLevelFrist(idMatch: string, data: any ): Observable<any> {
    return this.http
      .post(`${this.url}/match/registerJson/${idMatch}`, data, {
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  deleteLevel(
    idMatch: string,
    level: string,
    position: string,
    dataDelete: any
  ): Observable<any> {
    // Crear una instancia de HttpParams para agregar los datos a la URL
    const params = new HttpParams()
      .set('level', dataDelete.level)
      .set('position', dataDelete.position);

    // Agregar los datos a la URL
    const urlWithParams = `${
      this.url
    }/match/deleteLevel/${idMatch}/${level}/${position}/?${params.toString()}`;

    // Realizar la solicitud DELETE
    return this.http
      .delete(urlWithParams, {
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  searchLevelAndPosition(data: any): Observable<any> {
    return this.http
      .post(`${this.url}/match/searchPosition`, data, {
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  searchNodeParents(data: any): Observable<any> {
    return this.http
      .post(`${this.url}/match/searchParents`, data, {
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  deleteFirstLevel(idMatch: string, position: string): Observable<any> {
    return this.http
      .delete(`${this.url}/match/deleteFirstLevel/${idMatch}/${position}`, {
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }
}
