import { Component, Inject, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-image-cropper',
  templateUrl: './dialog-image-cropper.component.html',
  styleUrls: ['./dialog-image-cropper.component.scss']
})
export class DialogImageCropperComponent implements AfterViewInit {
  @ViewChild('imageElement', { static: false }) imageElement!: ElementRef<HTMLImageElement>;
  @ViewChild('cropContainer', { static: false }) cropContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('cropArea', { static: false }) cropArea!: ElementRef<HTMLDivElement>;

  private isCropping: boolean = false;
  private cropStartX: number = 0;
  private cropStartY: number = 0;
  private mouseMoveListener: any;
  private mouseUpListener: any;

  constructor(
    public dialogRef: MatDialogRef<DialogImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imagePreview: string },
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    const imgElement = this.imageElement.nativeElement;
    imgElement.onload = () => {
      this.adjustCropArea();
    };
    imgElement.src = this.data.imagePreview;
  }

  adjustCropArea() {
    const cropContainer = this.cropContainer.nativeElement;
    const cropArea = this.cropArea.nativeElement;

    const containerSize = Math.min(cropContainer.offsetWidth, cropContainer.offsetHeight);
    const cropSize = containerSize * 0.8; // El área de recorte el porcentaje

    cropArea.style.width = `${cropSize}px`;
    cropArea.style.height = `${cropSize}px`;
    cropArea.style.top = `${(cropContainer.offsetHeight - cropSize) / 2}px`;
    cropArea.style.left = `${(cropContainer.offsetWidth - cropSize) / 2}px`;
  }

  startCrop(event: MouseEvent) {
    this.isCropping = true;
    this.cropStartX = event.clientX - this.cropArea.nativeElement.offsetLeft;
    this.cropStartY = event.clientY - this.cropArea.nativeElement.offsetTop;

    this.mouseMoveListener = this.renderer.listen('window', 'mousemove', this.moveCrop.bind(this));
    this.mouseUpListener = this.renderer.listen('window', 'mouseup', this.endCrop.bind(this));
  }

  moveCrop(event: MouseEvent) {
    if (this.isCropping) {
      const cropArea = this.cropArea.nativeElement;
      const cropContainer = this.cropContainer.nativeElement;

      let newLeft = event.clientX - this.cropStartX;
      let newTop = event.clientY - this.cropStartY;

      // Limitar el movimiento del área de recorte dentro del contenedor
      newLeft = Math.max(0, Math.min(newLeft, cropContainer.offsetWidth - cropArea.offsetWidth));
      newTop = Math.max(0, Math.min(newTop, cropContainer.offsetHeight - cropArea.offsetHeight));

      cropArea.style.left = `${newLeft}px`;
      cropArea.style.top = `${newTop}px`;
    }
  }

  endCrop() {
    this.isCropping = false;
    if (this.mouseMoveListener) this.mouseMoveListener();
    if (this.mouseUpListener) this.mouseUpListener();
  }

  cropImage() {
    const imgElement = this.imageElement.nativeElement;
    const cropArea = this.cropArea.nativeElement;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const scaleX = imgElement.naturalWidth / imgElement.width;
    const scaleY = imgElement.naturalHeight / imgElement.height;
    const cropX = (cropArea.offsetLeft - imgElement.offsetLeft) * scaleX;
    const cropY = (cropArea.offsetTop - imgElement.offsetTop) * scaleY;
    const cropSize = cropArea.offsetWidth * scaleX;

    canvas.width = cropSize;
    canvas.height = cropSize;
    ctx!.drawImage(imgElement, cropX, cropY, cropSize, cropSize, 0, 0, cropSize, cropSize);
    const croppedImage = canvas.toDataURL('image/png');
    this.dialogRef.close(croppedImage);
  }
}
