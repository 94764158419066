<div class="sidebar-area bg-main-color-blue box-shadow" [ngClass]="{'active': isToggled}">
    <div class="logo">
        <div class="profile-btn" *ngIf="userInfo.img; else notFound">
            <a [routerLink]="['configurations/profile']"><img src="{{userInfo.img}}" alt="Profile" class="rounded-circle"></a>
        </div>
        <ng-template #notFound>
            <img src="assets/img/not-found.svg" alt="Profile" class="rounded-circle">
        </ng-template>
        <span class="logo--name d-flex align-items-center ml-10">
            <a [routerLink]="['configurations/profile']">
                {{userInfo.name}} {{ userInfo.last_name}}
            </a>
        </span>
    </div>

    <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>

    <ng-scrollbar visibility="hover" style="height: 100vh;">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <hr class="sidebar-hr-header">

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                              <i class="ri-pages-line"></i>
                              <span class="title">Dashboard</span>
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ul class="sidebar-sub-menu">
                          <li class="sidemenu-item">
                              <a [routerLink]="['dashboard/interactions']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                  Interacción
                              </a>
                          </li>

                      </ul>
                  </mat-expansion-panel>


                    <hr class="sidebar-hr">
                    <!-- <div>
                        <img src="../../../assets/img/ConHector/Personaje_acciones/icon.png" alt="Con-Héctor" class="section-icon">
                        <span class="blue-color">Con-Héctor</span>
                    </div> -->
                    <span class="sub-title blue-color">Con-Héctor</span>
                    <a [routerLink]="['participants/list']" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="ri-folder-user-line"></i>
                        <span class="title">Participantes</span>
                    </a>
                    <a [routerLink]="['lines/lists']" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="ri-phone-fill"></i>
                        <span class="title">Líneas país</span>
                    </a>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="ri-pages-line"></i>
                                <span class="title">Programas</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                <a [routerLink]="['programs/program_list']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Listado
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a [routerLink]="['programs/list-match']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Matchs
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                    <hr class="sidebar-hr">
                    <span class="sub-title blue-color">Configuración CMS</span>
                    <a [routerLink]="['configurations/users']" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="ri-user-fill"></i>
                        <span class="title">Usuarios</span>
                    </a>
                    <a [routerLink]="['configurations/modules']" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="ri-profile-fill"></i>
                        <span class="title">Perfiles</span>
                    </a>
                    <!-- <a [routerLink]="['configurations/modules']" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                      <i class="ri-profile-fill"></i>
                      <span class="title">Módulos</span>
                    </a> -->
                    <a [routerLink]="['configurations/variables']" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="ri-tools-line"></i>
                        <span class="title">Variables</span>
                    </a>
                    <!-- <hr class="sidebar-hr">

                    <a [routerLink]="['help']" class="sidebar-menu-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="ri-question-line"></i>
                        <span class="title">Ayuda</span>
                    </a> -->
                    <a routerLink="/logout" class="sidebar-menu-link sidebar-menu-link--last" (click)="logout()">
                        <i class="ri-shut-down-line"></i>
                        <span class="title">Cerrar sesión</span>
                    </a>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>
