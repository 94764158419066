import { Component } from '@angular/core';
import { CustomizerSettingsService } from 'src/app/core/services/customizer-settings.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(public themeService: CustomizerSettingsService) {}

  toggleCardBorderTheme() {
    this.themeService.toggleCardBorderTheme();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleCardBorderRadiusTheme() {
    this.themeService.toggleCardBorderRadiusTheme();
  }
}
