<app-card title="Programa">
  <form class="note-box" [formGroup]="programs">
    <div class="row">
      <!-- program_name -->
      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="d-block mb-12 fw-semibold subtitle-card-color"
          >Nombre del programa</label
        >
        <mat-form-field appearance="fill">
          <i class="ri-profile-line"></i>
          <mat-label>Ingresa Nombre</mat-label>
          <input formControlName="program_name" matInput required />
        </mat-form-field>
      </div>

      <!-- type -->

      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Tipo</label>
        <mat-form-field appearance="fill">
          <i class="ri-list-unordered"></i>
          <mat-label>Ingresa tipo</mat-label>
          <input
            type="text"
            placeholder="Escribe un tipo"
            aria-label="Number"
            matInput
            [formControl]="typeForm"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.type"
            >
              {{ option.type }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- coverage -->

      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Coverage</label>
        <mat-form-field appearance="fill">
          <i class="ri-list-unordered"></i>
          <mat-label>Selecciona</mat-label>
          <mat-select
            formControlName="coverage"
            [value]="programs.value.coverage"
          >
            <mat-option disabled>Selecciona</mat-option>
            <mat-option
              *ngFor="let coverage of listCoverage"
              [value]="coverage.name"
              >{{ coverage.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="category-label-container">
          <label class="d-block mb-12 fw-semibold subtitle-card-color">Categoría</label>
          <button class="simple-icon-button" (click)="toggleCategoryInput()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <mat-form-field appearance="fill" class="category-input">
          <i class="ri-layout-grid-fill"></i>
          <mat-select
            *ngIf="!isAddingNewCategory"
            formControlName="Subcategory"
            placeholder="Selecciona"
            [value]="programs.value.Subcategory"
          >
            <mat-option disabled>Selecciona</mat-option>
            <mat-option *ngFor="let category of listCategory" [value]="category.name">
              {{ category.name }}
            </mat-option>
          </mat-select>
          <input
            *ngIf="isAddingNewCategory"
            matInput
            formControlName="Subcategory"
            placeholder="Ingresa nueva categoría"
          />
        </mat-form-field>
      </div>


      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="d-block mb-12 fw-semibold subtitle-card-color"
          >Fecha inicio - Fecha Final</label
        >
        <mat-form-field>
          <mat-label>Seleccione rango del programa</mat-label>
          <i class="ri-calendar-event-fill"></i>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              formControlName="start_date"
              placeholder="Fecha de inicio"
            />
            <input
              matEndDate
              formControlName="end_date"
              placeholder="Fecha Final"
            />
          </mat-date-range-input>

          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>
      <div class="col-lg-12 col-md-12 col-sm-12 mt-15">
        <label class="d-block mb-12 fw-semibold subtitle-card-color"
          >Opciones del Menú</label
        >
        <mat-form-field appearance="fill">
          <i class="ri-information-line"></i>
          <mat-label>Ingresa las Opciones del Menú</mat-label>
          <textarea matInput rows="4" formControlName="menu_option"></textarea>
        </mat-form-field>
      </div>
    </div>
    <button
      mat-button
      type="submit"
      class="default-btn bg-main-color-blue"
      (click)="programEvent()"
    >
      Guardar
    </button>
  </form>
</app-card>

<!-- Card listado de pasos -->

<app-card title="Listado de Pasos" [breadCrumb]="false">

  <div class="recent-files-table table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th class="fw-semibold subtitle-card-color">Posición</th>
          <th class="fw-semibold subtitle-card-color">Multimedia</th>
          <th class="fw-semibold subtitle-card-color">Interacción</th>
          <th class="fw-semibold subtitle-card-color">Descripción</th>
          <th class="fw-semibold subtitle-card-color">Acciones</th>
        </tr>
      </thead>
      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
        <tr *ngFor="let step of listSteps" cdkDrag>
          <td class="subtitle-card-color">
            {{ step.numberStep }}
          </td>
          <td>
            <div class="name-info">
              <div *ngIf="!step.media; else multimedia">
                <p class="subtitle-card-color mb-0">Sin multimedia</p>
            </div>
            <ng-template #multimedia>
              <img
                *ngIf="step.media!.type === 'image'  || 'image/png'"
                [src]="step.media!.fileUrl"
                [alt]="step.media!.name"
                [style.max-width.px]="100"
                [style.max-height.px]="100"
              />
              <img
                *ngIf="step.media!.type === 'document'"
                src="assets/img/PDF_file_icon.svg"
                [alt]="step.media!.name"
                [style.max-width.px]="100"
                [style.max-height.px]="100"
              />
              <img
                *ngIf="step.media!.type === 'audio'"
                src="assets/img/note_voice.png"
                [alt]="step.media!.name"
                [style.max-width.px]="100"
                [style.max-height.px]="100"
              />
              <img
                *ngIf="step.media!.type === 'video'"
                src="assets/img/youtube.svg"
                [alt]="step.media!.name"
                [style.max-width.px]="100"
                [style.max-height.px]="100"
              />
          </ng-template>

            </div>
          </td>
          <td class="subtitle-card-color">
            {{ step.interaction }}
          </td>
          <td class="subtitle-card-color">
            <p
              class="subtitle-card-color mb-0"
              *ngIf="step.interaction === 'Informativo'"
              [matTooltip]="step.description ?? ''"
            >
              {{ step.description ?? "" | truncate : 20 }}
            </p>
            <p
              class="subtitle-card-color mb-0"
              *ngIf="step.interaction === 'Pregunta'"
              [matTooltip]="step.question ?? ''"
            >
              {{ step.question ?? "" | truncate : 20 }}
            </p>
          </td>

          <td>
            <button
              mat-icon-button
              class="blue-color"
              color="primary"
              matTooltip="Editar"
              (click)="openAddEventDialogMedia('300ms', '100ms', step)"
            >
              <mat-icon class="ri-edit-line"></mat-icon>
            </button>

            <button
              mat-icon-button
              class="blue-color"
              color="warn"
              (click)="deleteStep(step, $event)"
              matTooltip="Eliminar"
            >
              <mat-icon class="ri-delete-bin-line"></mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <!-- Skeleton Loader -->
      <ng-container>
        <tr >
          <td class="skeleton-bg">
            <div class="skeleton skeleton-text"></div>
          </td>
          <td class="skeleton-bg">
            <div class="skeleton skeleton-image"></div>
          </td>
          <td class="skeleton-bg">
            <div class="skeleton skeleton-text"></div>
          </td>
          <td class="skeleton-bg">
            <div class="skeleton skeleton-text"></div>
          </td>
          <td class="skeleton-bg">
            <div class="row justify-content-center">
              <button
                mat-button
                class="addButton"
                type="button"
                (click)="openAddEventDialogMedia('300ms', '100ms')"
                matTooltip="Agregar Paso"
                >
                <i class="ri-add-circle-line"></i> Agregar
              </button>

            </div>
          </td>
        </tr>
      </ng-container>
    </table>

  </div>
</app-card>
