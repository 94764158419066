<div
    class="settings-sidebar bg-white box-shadow"
    [ngClass]="{'active': isToggled}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="settings-header bg-gray p-20">
        <div class="d-flex align-items-center justify-content-space-between">
            <h5 class="m-0">Configuration Panel</h5>
            <img src="assets/img/ConHector/Conhector-logo-1.png" alt="logo-icon" width="40">
        </div>
    </div>
    <ng-scrollbar visibility="hover">
        <div class="settings-body">

            <div class="p-20">
                <span class="sub-title d-block mb-12 fw-semibold">Dark Mode</span>
                <button (click)="toggleTheme()" class="switch-btn" [class.active]="themeService.isDark()"></button>
            </div>
            <mat-divider></mat-divider>

        </div>
    </ng-scrollbar>
</div>

<button mat-fab class="tagus settings-btn bg-color-blue-button" (click)="toggle()" [ngClass]="{'active': isToggled}" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <mat-icon>settings</mat-icon>
</button>
