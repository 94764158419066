import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from '@core/interfaces/auth.interface';
import { User, Users } from '@core/interfaces/User.interface';
import { AuthService } from '@core/services/authentication/auth.service';
import { UsersService } from '@core/services/configurations/users.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss'],
})
export class LockScreenComponent implements OnInit {
  hide = true;

  saludoImg =
    '../../../../assets/img/ConHector/Personaje_acciones/ConHector_1.png';
  errorImg =
    '../../../../assets/img/ConHector/Personaje_acciones/ConHector_Dudoso.png';
  internalErrorImg = '../../../../assets/img/error_icons/error_500.gif';
  lockedUserImg = '../../../../assets/img/error_icons/locked.gif';

  public idUser: string = '';
  public userInfo: User = {};

  public loginForm = this.fb.group({
    email: [this.userInfo.email || ''],
    password: ['', Validators.required],
  });
  constructor(
    public fb: FormBuilder,
    public router: Router,
    public _authService: AuthService,
    public _userService: UsersService,
    public themeService: CustomizerSettingsService
  ) {
    this.idUser = localStorage.getItem('user_conhector') || '';
  }

  ngOnInit(): void {
    this.getInfoUser();
  }

  getInfoUser() {
    this._userService.getInfoUser(this.idUser).subscribe(
      (res: Users) => {
        if (res.ok) {
          this.userInfo = res.results[0];
        }
      },
      (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al obtener la información del usuario',
          text: err.error.message,
        });
      }
    );
  }

  login() {



    this.loginForm.get('email')?.setValue(this.userInfo.email || '');

    this._authService.loginService(this.loginForm.value).subscribe(
      (resp: Auth) => {
        if (resp.ok) {
          localStorage.setItem('user_conhector', resp.user?.id || '');
          localStorage.setItem('token_conhector', resp.user?.token || '');
          /* Swal.fire({
            title: 'Bienvenido',
            text: 'Haz ingresado al cms de CON HECTOR',
            imageUrl: this.saludoImg,
            imageWidth: 100,
            imageHeight: 200,
            imageAlt: 'Custom image',
          }); */
          this.router.navigate(['/']);
        }
      },
      (error) => {
        if (error.status === 500) {
          Swal.fire({
            title: 'Upps!!',
            text: 'Se ha presentado un error interno en el servidor, por favor intente más tarde. O comunicate con Sistemas',
            imageUrl: this.internalErrorImg,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',
          });
        } else if (error.status === 401) {
          Swal.fire({
            title: 'Upps!!',
            text: 'Tu cuenta ha sido bloqueada por seguridad, a tu correo se ha enviado un mensaje para desbloquearla',
            imageUrl: this.lockedUserImg,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',
          });
        } else {
          Swal.fire({
            title: 'Upps!!',
            text:
              error.error.msg ||
              'Ocurrió un error en la solicitud de inicio de sesión',
            imageUrl: this.errorImg,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',
          });
        }
      }
    );
  }
}
