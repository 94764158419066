import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.scss'],
})
export class TableDataComponent implements OnInit, OnChanges {
  @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @Input() data: any[] = [];
  @Input() columns: string[] = [];
  @Input() pageSizeOptions: number[] = [];
  @Input() pageIndex: number = 1;
  @Input() pageSize: number = 10;
  @Input() totalItems?: number;
  @Input() enableEditing: boolean = false;
  @Input() showAddRow: boolean = false;

  @Input() viewButton: boolean = false;
  @Input() radioButton: boolean = false;
  @Input() selectButton: boolean = false;
  @Input() editButton: boolean = false;
  @Input() deleteButton: boolean = false;
  @Input() stateButton: boolean = false;
  @Input() codeqrButton: boolean = false;
  @Input() multipleChoice: boolean = false;

  @Output() selectEvent = new EventEmitter<any>();
  @Output() selectCheckBoxEvent = new EventEmitter<any>();
  @Output() stateEvent = new EventEmitter<any>();
  @Output() viewEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() radioEvent = new EventEmitter<any>();
  @Output() codeqrEvent = new EventEmitter<any>();
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();
  @Output() addEvent = new EventEmitter<any>(); // Nuevo evento de salida para agregar

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  public totalLength: number = 0;

  private editedRows: Set<any> = new Set();
  private oldRow: any;

  ngOnInit(): void {
    this.updateDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && !changes['data'].firstChange) {
      this.updateDataSource();
    }
  }

  private updateDataSource(): void {
    let updatedData = [...this.data];
    if (this.showAddRow) {
      updatedData.push({ isAddRow: true });
    }
    this.dataSource = new MatTableDataSource(updatedData);
    this.updateTotalLength();
  }

  isEditMode(element: any): boolean {
    return this.enableEditing && element.isEditing;
  }

  startEdit(row: any) {
    if (this.enableEditing) {
      row.isEditing = true;
      this.oldRow = { ...row };
      this.showAddRow = false; // Ocultar el esqueleto cuando se está editando
    } else {
      this.ActionButton('edit', row);
    }
  }

  saveEdit(row: any) {
    row.isEditing = false;
    this.saveEvent.emit(row);
    this.showAddRow = true; // Rehabilitar el esqueleto después de guardar
    this.updateDataSource(); // Actualizar la fuente de datos
  }

  cancelEdit(row: any) {
    row.isEditing = false;
    this.cancelEvent.emit([row, this.oldRow]);
    this.showAddRow = true; // Rehabilitar el esqueleto después de cancelar
    this.updateDataSource(); // Actualizar la fuente de datos
  }
  selectedElements: any[] = [];
  ActionButton(action: string, rowData: any): void {
    if (action === 'delete') {
      this.deleteEvent.emit(rowData);
    } else if (action === 'view') {
      this.viewEvent.emit(rowData);
    } else if (action === 'edit') {
      this.editEvent.emit(rowData);
    } else if (action === 'select') {
      this.selectEvent.emit(rowData);
    } else if (action === 'radioButton') {
      this.radioEvent.emit(rowData);
    } else if (action === 'checkbox') {
      rowData.selected = !rowData.selected;
      rowData.selected ? this.selectedElements.push(rowData): this.selectedElements = this.selectedElements.filter(item => item !== rowData);
      this.selectCheckBoxEvent.emit(this.selectedElements);
    } else if (action === 'state') {
      this.stateEvent.emit(rowData);
    } else if (action === 'qrcode') {
      this.codeqrEvent.emit(rowData);
    }
  }

  applyFilter(event: any) {
    let textFilter = event.target.value;
    this.dataSource.filter = textFilter.trim().toLowerCase();
  }

  private updateTotalLength(): void {
    this.totalLength = this.data.length;
  }

  onPageChange(event?: any) {
    this.pageChange.emit(event);
  }

  addRow() {
    if (this.data.some(group => group.isEditing)) {
      Swal.fire({
        icon: 'warning',
        text: 'Complete la edición actual antes de agregar un nuevo grupo',
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        color: '#1d2c4d',
        confirmButtonColor: '#262D48',
        background: '#ffffff',
        width: '28rem',
      });
      return;
    }
    this.showAddRow = false; // Ocultar el esqueleto temporalmente
    this.addEvent.emit();
  }

  addNewGroupRow() {
    if (this.data.some(group => group.isEditing)) {
      Swal.fire({
        icon: 'warning',
        text: 'Complete la edición actual antes de agregar un nuevo grupo',
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        color: '#1d2c4d',
        confirmButtonColor: '#262D48',
        background: '#ffffff',
        width: '28rem',
      });
      return;
    }

    const newGroup = { id: '', name: '', description: '', isEditing: true };
    this.data.push(newGroup);
    this.showAddRow = true; // Asegurarse de que el esqueleto se muestre después de agregar una fila
    this.updateDataSource();
  }
}
