import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ListMatchComponent } from './pages/list-match/list-match.component';
import { ListProgramsComponent } from './pages/list-programs/list-programs.component';
import { CreateEditProgramsComponent } from './pages/create-edit-programs/create-edit-programs.component';

const routes: Routes = [
  {
    path: 'program_list',
    component: ListProgramsComponent,
    data:{
      firstBreadcrumb: {
        title: 'Con-Héctor',
        icon: '../../../assets/img/ConHector/Personaje_acciones/icon.png',
        url: ''
      },
      title: 'Listado de Programas',
      url: '/programs/program_list'
    }
  },
  {
    path: 'create_program',
    component: CreateEditProgramsComponent,
    data:{
      firstBreadcrumb: {
        title: 'Con-Héctor',
        icon: '../../../assets/img/ConHector/Personaje_acciones/icon.png',
        url: ''
      },
      title: 'Nuevo Programa',
      url: '/programs/create_program',
      parents: [
        {
          title: 'Listado de Programas',
          url: '/programs/program_list'
        }
      ]
    }
  },
  {
    path: 'edit_program',
    component: CreateEditProgramsComponent,
    data:{
      firstBreadcrumb: {
        title: 'Con-Héctor',
        icon: '../../../assets/img/ConHector/Personaje_acciones/icon.png',
        url: '/'
      },
      title: 'Editar Programa',
      url: '',
      parents: [
        {
          title: 'Listado de Programas',
          url: '/programs/program_list'
        }
      ]

    }
  },
  {
    path:'list-match',
    component: ListMatchComponent,
    data:{
      firstBreadcrumb: {
        title: 'Con-Héctor',
        icon: '../../../assets/img/ConHector/Personaje_acciones/icon.png',
        url: '/'
      },
      title: 'Listado de matchs',
      url: 'programs/program_list'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramsRoutingModule { }
