import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, reset_password } from '@core/interfaces/auth.interface';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public urlBack: string = environment.base_url;
  constructor(private http: HttpClient) { }


  loginService(data: any): Observable<Auth>  {
    return this.http.post<Auth>(`${this.urlBack}/auth/login`, data).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage =      'Ocurrió un error en la solicitud de inicio de sesión';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de inicio de sesión: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(error);
      })

    );
  }

  forgotPasswordService(email: string): Observable<AuthService> {

  return this.http.post<AuthService>(`${this.urlBack}/auth/forgot-password`, { email }).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage = 'Ocurrió un error en la solicitud de restablecimiento de contraseña';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de restablecimiento de contraseña: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    })
  );

  }

  getIdentity(): boolean {
    const token = localStorage.getItem('token_conhector') || '';
    const helper = new JwtHelperService();
    // Verificar si el token está vacío
    if (token === '') {
      return false;
    }

    // Verificar si el token ha expirado utilizando una función helper llamada isTokenExpired

    if (helper.isTokenExpired(token)) {
      return false;
    }

    // Si el token no está vacío y no ha expirado, se considera válido
    return true;
  }


  validateTokenEmail(token: string): Observable<any> {
    // Crear un objeto HttpParams con el único parámetro
    const params = new HttpParams().set('token', token);

    return this.http.get(`${this.urlBack}/auth/validarToken`, { params: params }).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'Ocurrió un error en la solicitud de validación de correo';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(`Error en la solicitud de validación de correo: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`);
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(errorMessage);
      })
    );
  }

  resetPassword(data: reset_password): Observable<any> {
    return this.http.post(`${this.urlBack}/auth/reset-password`, data, {}).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage =
          'Ocurrió un error en la solicitud de cambio de contraseña';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de cambio de contraseña: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(errorMessage);
      })
    );
  }

}
