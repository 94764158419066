<div class="h-100vh pt-50 pb-50">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="internal-error text-center">
                <img src="assets/img/error.png" class="mb-30" alt="error">
                <h4 class="mb-15 ms-auto me-auto">Looks like we have an internal error, please try again later.</h4>
                <a mat-raised-button class="tagus mt-10" routerLink="/">Got To Dashboard</a>
            </div>
        </div>
    </div>
</div>