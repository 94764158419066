import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ModulesComponent } from './modules/modules.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { AuthRoutingModule } from '@modules/auth/auth-routing.module';
import { ModulesRoutingModule } from '@modules/modules-routing.module';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'dashboard', component: ModulesComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AuthRoutingModule,ModulesRoutingModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
