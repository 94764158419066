import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ProgramsFilter } from '@core/interfaces/Programs.interface';
import { FilterConfig } from '@core/interfaces/filters.interface';
import { FilterService } from '@core/services/filters/filter.service';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search-input.component.html',
  styleUrls: []
})
export class SearchInputComponent implements OnInit, OnDestroy {

  /** Observable manual para manejar el debounce */
  private debouncer: Subject<any> = new Subject<any>();
  /** Suscripción al debouncer para gestionar la entrada con tiempo de espera */
  private debouncerSubscription?: Subscription;

  /** Evento de salida que emite los filtros aplicados */
  @Output() filterEvent = new EventEmitter<any>();

  /** Formulario que contiene los controles de los filtros */
  filtersForm: FormGroup;

  /** Configuración de los filtros */
  filterConfigs: FilterConfig[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private filterService: FilterService
  ) {
    // Inicializa el formulario de filtros vacío
    this.filtersForm = this.formBuilder.group({});
    // Configura los filtros iniciales
    this.initializeFilters();
  }

  ngOnInit(): void {
    // Suscripción a los cambios en la configuración de filtros desde el servicio
    this.filterService.filterConfig$.subscribe(
      (filters) => {
        this.filterConfigs = filters;
        this.initializeFilters();
      }
    );

    // Suscripción al debouncer para aplicar filtros con un tiempo de espera
    this.debouncerSubscription = this.debouncer
      .pipe(
        // No emite el valor hasta que el flujo de eventos se detenga por el tiempo especificado
        debounceTime(500)
      )
      .subscribe(event => {
        const textFilter = event.target.value;
        // Emite el evento de filtro
        this.filterEvent.emit(event);
        // Aplica los filtros con el valor de búsqueda
        this.applyFilters(textFilter);
      });
  }

  /**
   * Inicializa los controles del formulario de filtros basado en la configuración recibida
   */
  initializeFilters() {
    // Reinicia el formulario de filtros
    this.filtersForm = this.formBuilder.group({});
    // Agrega controles al formulario según la configuración de los filtros
    this.filterConfigs.forEach(config => {
      this.filtersForm.addControl(config.name, new FormControl(null));
    });

    // Suscripción a los cambios en el formulario para aplicar los filtros automáticamente
    this.filtersForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }

  /**
   * Aplica los filtros y los envía al servicio de filtros
   * @param {string} [searchValue] - Valor de búsqueda parcial
   */
  applyFilters(searchValue?: string) {
    // 1. Obtener los valores de los filtros del formulario
    const sendFilters: any = {};
    const filters: ProgramsFilter = this.filtersForm.value;
    // Si hay un valor de búsqueda, agregarlo a los filtros a enviar
    if (searchValue) {
      sendFilters["searchValue"] = searchValue;
    }

    // 2. Filtrar los valores nulos y agregar los filtros configurados
    for (const filterIn of this.filterConfigs) {
      if (filters[filterIn.name]) {
        sendFilters[filterIn.dbName] = filters[filterIn.name];
      }
    }
    // Establece los filtros en el servicio de filtros
    this.filterService.setFilters(sendFilters);
  }

  /**
   * Envía el valor de búsqueda al debouncer
   */
  sendSearch(event: any) {
    this.debouncer.next(event);
  }

  /** Método que se llama cuando la instancia del componente es destruida */
  ngOnDestroy(): void {
    // Desuscribe del debouncer para evitar fugas de memoria
    this.debouncerSubscription?.unsubscribe();
  }
}
