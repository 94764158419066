import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatchService } from '@core/services/programs/match.service';
import { TreeNode } from 'primeng/api';
import Swal from 'sweetalert2';
import { DialogAddLevelComponent } from '../dialog-add-level/dialog-add-level.component';

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.css']
})
export class DiagramComponent implements OnInit {
  data2: TreeNode[] = [];

  @Input() dataLevels: any;
  @Input() Match: any;
  @Output() reloadData = new EventEmitter<any>();

  transformedData: TreeNode<any>[] = [];

  selectedNodes!: TreeNode[];
  public nodoAnterior: any;

  constructor(public dialog: MatDialog, private matchService: MatchService) {}

  ngOnInit() {
    this.transformedData = this.transformBackendData(this.dataLevels, 1, true, true);
  }

  onNodeSelect(event: any) {
    console.log(event);
  }

  onNodeContextMenu(event: MouseEvent, nodeData: any, data: any) {
    const dialogRef = this.dialog.open(DialogAddLevelComponent, {
      width: '70vw',
      data: {
        level: nodeData.level,
        position: nodeData.position,
        Match: this.Match,
        isUpdate: 'notUpdate',
        multipleChoise: true
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("result", result)
      this.reloadInfo(result);
    });
    event.preventDefault();
  }

  transformBackendData(levels: any | any[], level: number, isYellow: boolean, isFirst: boolean = false): TreeNode[] {
    if (!Array.isArray(levels)) {
      levels = [levels];
    }

    return levels.map((levelData: any) => {
      const treeNode: TreeNode = {
        expanded: true,
        type: 'person',
        styleClass: isFirst ? 'dark-blue-node' : this.getNodeStyleClass(isYellow),
        data: {
          name: levelData.program.program_name,
          level: level,
          position: levelData.position,
        },
        children: this.transformBackendData(
          levelData.children || [],
          level + 1,
          !isYellow
        ),
      };
      isFirst = false; // After the first node, reset isFirst to false
      return treeNode;
    });
  }

  getNodeStyleClass(isYellow: boolean): string {
    return isYellow ? 'yellow-node' : 'light-blue-node';
  }

  deleteOrUpdate(nodeData: any, data: any) {

    this.searchNodeParentsByLevelPosition(
      nodeData.position,
      nodeData.level,
      this.Match.levels
    );

    Swal.fire({
      title: '¿Qué acción deseas realizar?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Actualizar',
      denyButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#262D48', // Color del botón "Actualizar"
      denyButtonColor: '#FF6D39', // Color del botón "Eliminar"
      cancelButtonColor: '#6c757d', // Color del botón "Cancelar",
      width: '30rem',
      customClass: {
        confirmButton: 'swal-custom-confirm',
        denyButton: 'swal-custom-deny',
        cancelButton: 'swal-custom-cancel',
        popup: 'swal-custom-popup',
        title: 'swal-custom-title',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const dialogRef = this.dialog.open(DialogAddLevelComponent, {
          width: '70vw',
          data: {
            level: nodeData.level,
            position: nodeData.position,
            Match: this.Match,
            isUpdate: 'update',
            multipleChoise: false
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          console.log("result", result)
          this.reloadInfo(result);
        });
      } else if (result.isDenied) {
        this.deleteProgram(this.Match.id, nodeData, this.nodoAnterior);
      }
    });

  }

  deleteProgram(matchId: string, nodeData: any, nodoAnterior: any) {
    this.matchService
      .deleteLevel(matchId, nodoAnterior.level, nodoAnterior.position, nodeData)
      .subscribe(
        (res) => {
          Swal.fire({
            icon: 'error',
            title: 'Se ha eliminado el programa',
            iconHtml: '<i class="ri-close-circle-line" style="color: red;"></i>',
            customClass: {
              popup: 'swal-custom-popup',
              title: 'swal-custom-title',
              htmlContainer: 'swal-custom-content'
            },
            showConfirmButton: false,
            timer: 3000,
            backdrop: false,
            toast: true,
            position: 'top-end',
            background: '#ffecec',
            color: '#1d2c4d',
            padding: '1rem',
            width: '24rem',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });
          this.reloadInfo({method: "delete", response: res.match});
        },
        (err) => {
          console.log(err);
          Swal.fire('Se ha presentado un error', '', 'error');
        }
      );
  }

  searchNodeParentsByLevelPosition(
    position: string,
    level: Number,
    levelslist: any
  ) {
    let data = {
      targetPosition: position,
      targetLevel: level,
      levels: levelslist,
    };

    this.matchService.searchNodeParents(data).subscribe(
      (res) => {
        if (res.ok) {
          this.nodoAnterior = res.result;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteFirstLevelComplete(node: TreeNode<any>) {
    this.matchService
      .deleteFirstLevel(this.Match.id, node.data.position)
      .subscribe(
        (res) => {
          if (res.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Se ha eliminado el programa',
              iconHtml: '<i class="ri-check-line" style="color: green;"></i>',
              customClass: {
                popup: 'swal-custom-popup',
                title: 'swal-custom-title',
                htmlContainer: 'swal-custom-content'
              },
              showConfirmButton: false,
              timer: 3000,
              backdrop: false,
              toast: true,
              position: 'top-end',
              background: '#ffffff',
              color: '#1d2c4d',
              padding: '1rem',
              width: '30rem',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            });
            const dataResponse = {
              method: 'deleteFirst',
              response: {
                id: this.Match.id,
                position: node.data.position
              }
            }
            this.reloadInfo(dataResponse);
          }
        },
        (err) => {
          console.log(err);
          Swal.fire('Se ha presentado un error', '', 'error');
        }
      );
  }

  reloadInfo(data?: any) {
    this.reloadData.emit(data);
  }
}
