import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { TruncatePipe } from './pipes/truncate.pipe';
import { FormatPhonePipe } from './pipes/format-phone.pipe';
import { PaginatePipe } from './pipes/paginator.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { FormateState } from './pipes/format-state.pipe';


const coreModule=[
  TruncatePipe,
  FormatPhonePipe,
  PaginatePipe,
  SearchFilterPipe,
  FormateState
]

@NgModule({
  declarations: [coreModule],
  imports: [
    CommonModule,
    CoreRoutingModule
  ],
  exports:[
    coreModule
  ]

})
export class CoreModule { }
