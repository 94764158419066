<div class="dialog-custom" #dialog>
  <div class="dialog-header">
    <h1 mat-dialog-title>{{ title }}</h1>
    <button mat-icon-button class="close-button" (click)="ActionButton('close', $event)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="content">
    <ng-content></ng-content>
  </div>
  <div mat-dialog-actions class="actions">
    <button mat-button class="default-btn tagus" *ngIf="buttonAction" (click)="ActionButton('save', $event)" cdkFocusInitial>Confirmar</button>
    <button mat-button class="default-btn btn-cancel" *ngIf="buttonClose" (click)="ActionButton('close', $event)">Cancelar</button>
  </div>
</div>
