import { Component, OnInit } from '@angular/core';

import { ToggleService } from 'src/app/core/services/toggle.service';
import { CustomizerSettingsService } from 'src/app/core/services/customizer-settings.service';
import { Router } from '@angular/router';
import { UsersService } from '@core/services/configurations/users.service';
import { User, Users } from '@core/interfaces/User.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  panelOpenState = false;
  isToggled = false;
  public userInfo: User = {};

  constructor(
    private toggleService: ToggleService,
    public themeService: CustomizerSettingsService,
    public router: Router,
    private _userService: UsersService,
  ) {
    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
  }

  ngOnInit(): void {
    this.getInfoUser();
  }

  getInfoUser() {
    let id = localStorage.getItem('user_conhector') || '';
    this._userService.getInfoUser(id).subscribe((resp: Users) => {
      if (resp.ok) {
        this.userInfo = resp.results[0];

      }
    });
  }

  logout(){
    localStorage.removeItem('token_conhector');
    this.router.navigate(['/lockscreen']);
  }

  toggle() {
    this.toggleService.toggle();
  }

  toggleSidebarTheme() {
    this.themeService.toggleSidebarTheme();
  }

  toggleHideSidebarTheme() {
    this.themeService.toggleHideSidebarTheme();
  }
}
