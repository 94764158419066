<app-card [title]="title">
  <form autocomplete="off" novalidate [formGroup]="linesForm">
    <h5 class="fw-semibold mb-9 tertiary-blue-color">Ingresa los datos de la Nueva Línea</h5>
    <div class="row mt-20">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="tagus-form-group">
          <label class="d-block mb-12 fw-semibold subtitle-card-color">Nombre</label>
          <mat-form-field appearance="fill">
            <i class="ri-user-3-line"></i>
            <input matInput placeholder="Nombre de la línea" formControlName="name" required />
          </mat-form-field>
          <div *ngIf="linesForm.controls['name'].invalid && (linesForm.controls['name'].dirty || linesForm.controls['name'].touched)" class="text-danger">
            <div *ngIf="linesForm.controls['name'].errors?.['required']">El nombre es requerido.</div>
            <div *ngIf="linesForm.controls['name'].errors?.['minlength']">El nombre debe tener al menos 2 caracteres.</div>
            <div *ngIf="linesForm.controls['name'].errors?.['maxlength']">El nombre no puede tener más de 50 caracteres.</div>
          </div>
          <label class="d-block mb-12 fw-semibold subtitle-card-color">Indicativo</label>
          <mat-form-field appearance="fill" [ngClass]="{'invalid-input': linesForm.controls['indicative'].invalid && (linesForm.controls['indicative'].dirty || linesForm.controls['indicative'].touched)}">
            <i class="ri-phone-line"></i>
            <input matInput placeholder="ejemplo: 57" maxlength="3" formControlName="indicative" required />
          </mat-form-field>
          <div *ngIf="linesForm.controls['indicative'].invalid && (linesForm.controls['indicative'].dirty || linesForm.controls['indicative'].touched)" class="text-danger">
            <div *ngIf="linesForm.controls['indicative'].errors?.['required']">El indicativo es requerido.</div>
            <div *ngIf="linesForm.controls['indicative'].errors?.['minlength']">El indicativo debe tener al menos 2 caracteres.</div>
            <div *ngIf="linesForm.controls['indicative'].errors?.['maxlength']">El indicativo no puede tener más de 50 caracteres.</div>
          </div>
          <label class="d-block mb-12 fw-semibold subtitle-card-color">Proyectos</label>
          <mat-form-field appearance="fill">
            <i class="ri-projector-line"></i>
            <mat-label>Selecciona</mat-label>
            <mat-select formControlName="project" required>
              <mat-option disabled>Selecciona</mat-option>
              <mat-option *ngFor="let project of listProject" [value]="project.project">{{ project.project }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="linesForm.controls['project'].invalid && (linesForm.controls['project'].dirty || linesForm.controls['project'].touched)" class="text-danger">
            <div *ngIf="linesForm.controls['project']?.hasError('required')">Debes seleccionar un proyecto.</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="tagus-form-group">
          <label class="d-block mb-12 fw-semibold subtitle-card-color">Descripción</label>
          <mat-form-field appearance="fill">
            <i class="ri-information-line"></i>
            <mat-label>Describe la linea...</mat-label>
            <textarea matInput rows="10" formControlName="description"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>

  <h5 class="fw-semibold mb-20 me-3 tertiary-blue-color">Listado Grupos</h5>
  <app-table-data
    [data]="listGroup"
    [dataSource]="dataSource"
    [columns]="displayedColumns"
    [pageSizeOptions]="[10, 15, 20]"
    [enableEditing]="true"
    [editButton]="true"
    [deleteButton]="true"
    [showAddRow]="true"
    [viewButton]="false"
    [stateButton]="false"
    [selectButton]="false"
    [codeqrButton]="true"
    (deleteEvent)="deleteGroup($event)"
    (saveEvent)="saveGroup($event)"
    (addEvent)="addNewGroupRow()"
    (cancelEvent)="cancelGroup($event)"
    (pageChange)="onPageChange($event)"
    (codeqrEvent)="editCampain($event)">
  </app-table-data>

  <mat-divider></mat-divider>
  <br />
  <div class="text-end">
    <button mat-flat-button class="bg-main-color-blue fw-semibold" (click)="saveLine()">Guardar</button>
  </div>
</app-card>
