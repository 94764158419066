import { Component } from '@angular/core';
import { SpinnerService } from '@core/services/shared/spinner.service';

@Component({
  selector: 'app-spinner',
  template: `<div class="loader-container" *ngIf="this.spinnerService.getLoading()">
  <div class="letter-c">C</div>
  <div class="letter-h">H</div>
</div>`,
  styleUrls: ['./spinner.component.css'],
})
export class SpinnerComponent {
  constructor(public spinnerService: SpinnerService) {
  }
}
