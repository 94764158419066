import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@core/interfaces/modules.interface';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModulesService {
  public urlBackend: string = environment.base_url;
  public headers: any;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization',`Bearer ${ localStorage.getItem('token_conhector') || ''}`)
    .set('idUser', localStorage.getItem('user_conhector') || '');

  }

  getListModules(): Observable<Modules> {
    return this.http
      .get<Modules>(`${this.urlBackend}/configurations/modules`, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListModyles: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  modulePermission(url: string): Observable<Modules> {
    const params = new HttpParams().set('module', url);
    return this.http
      .get<Modules>(`${this.urlBackend}/configurations/user/validatePremission`, {
        params: params,
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de modulePermission: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }
}
