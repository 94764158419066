
<app-card [title]="title" class="campaign-card">
  <div class="container">
    <div class="row">
      <!-- Configuración del Mensaje -->
      <div class="col-lg-4 col-md-4 col-sm-12">
        <div class="form-section">
          <h3 class="section-title">Configuración del Mensaje</h3>
          <h4 class="section-subtitle subtitle-card-color">Mensaje del usuario</h4>
          <mat-form-field appearance="fill" class="full-width custom-textarea">
            <textarea
              matInput
              rows="3"
              id="message"
              [(ngModel)]="draftMessage"
              name="userMessage"
              placeholder="Escribe aquí tu mensaje..."
              (input)="onInput()"
              (focus)="onFocus()"
            ></textarea>
          </mat-form-field>
          <div class="options-section mt-4 tagus-form-group">
            <h3 class="section-title">Configuración del Código QR</h3>
            <h4 class="section-subtitle subtitle-card-color">Opciones</h4>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <mat-form-field appearance="fill" class="full-width">
                  <i class="ri-arrow-down-circle-line"></i>
                  <mat-label>Tipo de Elemento</mat-label>
                  <mat-select [(ngModel)]="elementType" value="canvas">
                    <mat-option disabled>Selecciona</mat-option>
                    <mat-option value="canvas">Canvas (Recomendado)</mat-option>
                    <mat-option value="img">Imagen</mat-option>
                    <mat-option value="url">URL</mat-option>
                    <mat-option value="svg">SVG</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-6 col-md-6">
                <mat-form-field appearance="fill" class="full-width">
                  <i class="ri-bnb-fill"></i>
                  <mat-label>Tamaño</mat-label>
                  <input matInput placeholder="Tamaño del QR (pixeles)" [(ngModel)]="width" />
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <h5 class="section-subtitle subtitle-card-color">Adjuntar Imagen (Opcional)</h5>
                <ngx-dropzone (change)="onSelect($event)">
                  <ngx-dropzone-label>Suelte los archivos aquí o haga clic para cargarlos.</ngx-dropzone-label>
                  <ngx-dropzone-image-preview *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-image-preview>
                </ngx-dropzone>
              </div>
            </div>
            <div class="row col-lg-12 col-md-12 mt-4">
              <h5 class="section-subtitle ol-lg-12 col-md-12 subtitle-card-color">Colores (Opcional)</h5>
              <div class="col-lg-6 col-md-6">
                <div class="color-input">
                  <label class="subtitle-card-color">Primer Plano</label>
                  <button
                    mat-stroked-button
                    [style.background]="colorDark"
                    [(colorPicker)]="colorDark"
                    [cpOutputFormat]="'hex'"
                  >
                    {{ colorDark }}
                  </button>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="color-input">
                  <label class="subtitle-card-color">Fondo</label>
                  <button
                    mat-stroked-button
                    [style.background]="colorLight"
                    [(colorPicker)]="colorLight"
                    [cpOutputFormat]="'hex'"
                  >
                    {{ colorLight }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 form-container">
        <div class="col-lg-12 col-md-12 col-sm-12 tagus-form-group">
          <h3 class="section-title mb-25 mt-10">Gestión de Preguntas de la Campaña</h3>
          <form [formGroup]="questionForm" (ngSubmit)="addQuestion()" class="row tagus-form-group ">
            <!-- Pregunta Input -->
            <div class="form-group" class="col-lg-4 col-md-4 col-sm-12">
              <mat-form-field appearance="fill" class="w-100">
                <i class="ri-questionnaire-fill"></i>
                <mat-label>Descripción</mat-label>
                <!-- <input matInput placeholder="Escribe una pregunta" formControlName="question"> -->
                <textarea

                    matInput
                    rows="1"
                    formControlName="question"
                    placeholder="Escribe una pregunta"
                  ></textarea>
              </mat-form-field>
            </div>
            <!-- Tipo de Pregunta Select -->
            <div class="form-group" [ngClass]="{'col-lg-2 col-md-2 col-sm-12': isMultipleChoice, 'col-lg-4 col-md-4 col-sm-12': !isMultipleChoice}">
              <mat-form-field appearance="fill" class="w-100">
                <i class="ri-checkbox-multiple-blank-line"></i>
                <mat-label>Tipo de Pregunta</mat-label>
                <mat-select formControlName="type" (selectionChange)="onTypeChange($event)">
                  <mat-option value="text">Texto</mat-option>
                  <mat-option value="multiple-choice">Opción Múltiple</mat-option>
                  <mat-option value="informative">Informativo</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Opciones Input -->
            <div *ngIf="isMultipleChoice" class="col-lg-4 col-md-4 col-sm-12" >
              <mat-form-field appearance="fill" class="w-100">
                <i class="ri-chat-settings-line"></i>
                <mat-label>Opción 1, Opción 2, ...</mat-label>
                <input matInput placeholder="Opción 1, Opción 2, Opción 3" formControlName="options">
              </mat-form-field>
            </div>
             <!-- Botón de Agregar -->
             <div  [ngClass]="{'col-lg-2 col-md-2 col-sm-12': isMultipleChoice, 'col-lg-4 col-md-4 col-sm-12': !isMultipleChoice}">
              <button mat-raised-button color="primary" type="submit" [disabled]="questionForm.invalid" class="full-height-button w-100 bg-color-blue-button" >
                {{ isMultipleChoice  ? 'Agregar' : 'Agregar Pregunta' }}
              </button>
            </div>
          </form>
        </div>
        <!-- Tabla de Preguntas -->
        <div class="col-lg-12 col-md-12 col-sm-12" style="max-height: 500px; min-height: 100%; overflow-y: auto;">
          <div class="recent-files-table table-responsive">
            <table mat-table [dataSource]="dataSource" cdkDropList (cdkDropListDropped)="drop($event)" class="mat-elevation-z8">
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Posición</th>
                <td mat-cell *matCellDef="let question; let i = index">{{ i + 1 }}</td>
              </ng-container>
              <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef>Pregunta</th>
                <td mat-cell *matCellDef="let question; let i = index">
                  <ng-container *ngIf="editingIndex === i; else viewMode">
                    <textarea
                      class="inputEdit"
                      matInput
                      rows="1"
                      [(ngModel)]="editedQuestion.question"
                    ></textarea>
                  </ng-container>

                  <ng-template #viewMode>
                    <span matTooltip="{{  question.question }}" matTooltipPosition="above">
                      {{ question.question | truncate:20 }}
                    </span>
                  </ng-template>
                </td>
              </ng-container>
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Tipo</th>
                <td mat-cell *matCellDef="let question; let i = index">
                  <ng-container *ngIf="editingIndex === i; else viewType">
                    <mat-select [(ngModel)]="editedQuestion.type">
                      <mat-option value="text">Texto</mat-option>
                      <mat-option value="multiple-choice">Opción Múltiple</mat-option>
                      <mat-option value="informative">Informativo</mat-option>
                    </mat-select>
                  </ng-container>
                  <ng-template #viewType>{{ question.type }}</ng-template>
                </td>
              </ng-container>
              <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef>Opciones</th>
                <td mat-cell *matCellDef="let question; let i = index">
                  <ng-container *ngIf="editingIndex === i && editedQuestion.type === 'multiple-choice'; else viewOptions">
                    <input matInput [(ngModel)]="editedQuestion.options">
                  </ng-container>
                  <ng-template #viewOptions>
                    <span matTooltip="{{  question.options ? question.options.join(', ') : 'N/A' | truncate:20}}" matTooltipPosition="above">
                      {{ question.options ? question.options.join(', ') : 'N/A' }}
                    </span>
                  </ng-template>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let question; let i = index">
                  <ng-container *ngIf="editingIndex === i; else editMode">
                    <button mat-icon-button class="blue-color" (click)="saveQuestion(i)">
                      <mat-icon>check</mat-icon>
                    </button>
                    <button mat-icon-button class="blue-color" (click)="cancelEdit()">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </ng-container>
                  <ng-template #editMode>
                    <button mat-icon-button class="blue-color" (click)="editQuestion(question, i)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button class="blue-color" (click)="deleteQuestion(question)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </ng-template>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag></tr>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</app-card>

<app-card title="Previsualización y Código QR" [breadCrumb]="false">
  <div class="row">
    <div class="tagus-form-group col-lg-6 col-md-6 col-sm-12">
      <div class="preview-section">
        <div class="iphone-container">
          <app-phone-view
          [sentMessage]="sentMessage"
          [questions]="questions"
          [phoneHeight]="'height: 760px;'"
          ></app-phone-view>
        </div>
      </div>
    </div>

    <div id="result-container" class="tagus-form-group col-lg-6 col-md-6 col-sm-12">
      <h3 class="section-title">Previsualización Código QR</h3>
      <div class="d-flex justify-content-center qrcodeImage">
        <qrcode #parent [elementType]="elementType" [errorCorrectionLevel]="errorCorrectionLevel" [qrdata]="qrdata"
          [scale]="scale" [width]="width" [allowEmptyString]="true" [className]="cssClass" [colorDark]="colorDark"
          [colorLight]="colorLight" [margin]="margin" [alt]="alt" [ariaLabel]="ariaLabel" [title]="title"
          [imageSrc]="imageSrc" [imageHeight]="imageHeight" [imageWidth]="imageWidth" (qrCodeURL)="onChangeURL($event)">
        </qrcode>
      </div>
      <div class="downloadButton d-flex justify-content-center">
        <button class="default-btn bg-color-blue-button" *ngIf="this.elementType !== 'svg'" mat-raised-button
          #qrCodeDom (click)="saveAsImage(parent)">
          Descargar código QR
        </button>
        <a *ngIf="this.elementType === 'svg'" mat-raised-button color="primary" [href]="qrCodeSrc"
          download="{{groupName}}">Descargar código QR</a>
      </div>
      <form class="form-full-width mt-10">
        <mat-form-field class="input-full-width" appearance="fill">
          <i class="ri-qr-code-line"></i>
          <mat-label>QR Code (qrdata)</mat-label>
          <input readonly matInput name="qrdata" placeholder="qrdata" [(ngModel)]="qrdata" />
          <button mat-icon-button matSuffix color="primary" (click)="copyToClipboard()">
            <mat-icon class="blue-color">file_copy</mat-icon>
          </button>
        </mat-form-field>
      </form>
      <div class="text-end">
        <button mat-flat-button class="bg-main-color-orange-button fw-semibold" (click)="onSubmit(parent)">Guardar</button>
      </div>
      <span class="mat-mdc-button-touch-target"></span>
    </div>
  </div>
</app-card>
