import { Observable, catchError, map, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { Step, Steps } from '@core/interfaces/Steps.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StepsService {
  public urlBackend: string = environment.base_url;
  public headers: any;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${localStorage.getItem('token_conhector')}`)
      .set('idUser', localStorage.getItem('user_conhector') || ' ');
  }

  getStepByProgram(id: string): Observable<Steps> {
    return this.http
      .get<Steps>(`${this.urlBackend}/steps/listStepByProgram/${id}`, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de los pasos';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de getStepByProgram: ${error.status},` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  registerSteps(data: Array<Step>, idProgram: string): Observable<Steps> {
    const params = new HttpParams().set('idProgram', idProgram);

    return this.http
      .post<Steps>(`${this.urlBackend}/steps`, data, {
        params: params,
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de los pasos';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de registerSteps: ${error.status},` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  updatedStep(id: string, data: Step): Observable<any> {
    return this.http
      .put(`${this.urlBackend}/steps/${id} `, data, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de los pasos';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de updatedStep: ${error.status},` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  deleteStep(id: string): Observable<any> {
    return this.http
      .delete(`${this.urlBackend}/steps/${id}`, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de los pasos';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de deleteStep: ${error.status},` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  updatePosition(data: Step[]): Observable<any> {
    return this.http
      .post(`${this.urlBackend}/steps/updatePosition`, data, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de los pasos';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de registerSteps: ${error.status},` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }
}
