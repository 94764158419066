import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LinesGroupsService } from '@core/services/linesGroups/lines-groups.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'add-line-dialog',
  templateUrl: './dialog-group.component.html',
})
export class DialogGroupBox  {

  public Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  public groupForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    idLines: [''],
  });

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogGroupBox>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _lineGroupService: LinesGroupsService
    
  ) {

    if (data) {
      if(data.group){
        this.groupForm.patchValue({
          name: data.group?.name,
          description: data.group?.description,
          idLines: data.idLines,
        });
      }
    }
  }

  close(): void {
    if (this.data.type === 'new' ) {
      this.dialogRef.close(this.groupForm.value);
      
    } else if(this.data.type === 'edit') {
      this._lineGroupService.updateGroupById(this.groupForm.value, this.data.group.id).subscribe(
        resp =>{
          if (resp.ok) {
            this.Toast.fire({
              icon: "success",
              title: "Se ha actualizado el grupo correctamente"
            });
            this.dialogRef.close();
          }
        }, err => {
          console.log(err);
        }
      )
    }

  }



closed(): void {
    this.dialogRef.close();
  }
}
