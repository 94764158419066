<app-card title="Listado de Perfiles"  [titleButton]="'Nuevo Perfil'" (action)="openAddEventDialog('300ms', '100ms')" >
  <div class="row">
    <div class="col-sm-6" *ngFor="let profile of listProfiles; let  i = index">
      <mat-card class="mb-25 p-team-card tagus-card">
          <mat-card-header>
              <div>
                  <h5 class="mb-8 text-white ">{{ profile.name_profile }}</h5>
                  <p class="text-white ">{{ profile.name_profile }}
                    <span class="badge fw-medium" *ngIf="profile.state === 'A'" >Activo</span>
                    <span class="badge inactive fw-medium" *ngIf="profile.state === 'I'" >Inactivo</span>
                  </p>
              </div>
              <div>
                  <button type="button" mat-button class="position-relative dot-btn p-0 border-none" [matMenuTriggerFor]="monthMenu">
                      <i class="ri-more-fill"></i>
                  </button>
                  <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
                      <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="openEditProfileDialog('300ms', '100ms', profile)">
                        <span><i class="ri-edit-line"></i>Editar</span>
                      </button>

                      <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="changeStatus(profile ?? '')">
                        <span *ngIf="profile.state === 'A'"><i class="flaticon-dislike"></i>Inactivar</span>
                      <span *ngIf="profile.state === 'I'"><i class="flaticon-like"></i>Activar</span>

                      </button>

                      <button
                      mat-menu-item
                      type="button"
                      class="bg-transparent border-none position-relative"
                      (click)="deleteProfile(profile ?? '', i) ">

                      <i class="ri-delete-bin-line"></i> Eliminar
                    </button>

                  </mat-menu>
              </div>
          </mat-card-header>
          <mat-card-content>
            <h4 class="mt-10 mb-10 subtitle-card-color">Módulos</h4>
              <div class="d-flex align-items-center justify-content-space-between">
                <mat-divider></mat-divider>
                <br>
                <div class="tags-list">
                  <a
                    class="fw-medium blue-chip"
                    *ngFor="let module of profile.list_modules"
                    [matTooltip]="module.code_module || 'Sin Asignación'"
                    routerLink="/members-list"
                    >{{ module.name_module }}</a
                  >
                </div>
              </div>
              <h4 class="mt-10 mb-10 subtitle-card-color">Permisos</h4>
              <div class="d-flex align-items-center justify-content-space-between">
                <mat-divider></mat-divider>
                <br>
                <div class="tags-list">
                  <a
                    class="fw-medium blue-chip"
                    *ngFor="let permission of profile.list_permissions"
                    [matTooltip]="permission || 'Sin Asignación'"
                    >{{ permission }}</a
                  >
                </div>
              </div>
              <div class="info d-flex align-items-center justify-content-space-between">
                  <div>
                      <span class="d-block subtitle-card-color fs-14 mb-5"># de Usuarios</span>
                      <span class="d-block gray-color fs-16 fw-semibold"></span>
                  </div>
                <div>
                    <a mat-raised-button class="gray" (click)="openListUsersByProfile('300ms', '100ms', profile)">Ver Usuarios<i class="flaticon-chevron"></i></a>
                </div>
              </div>
          </mat-card-content>
      </mat-card>
    </div>
  </div>
</app-card>







