import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  @Input() title: string = '';
  @Input() buttonClose: boolean = true;
  @Input() buttonAction: boolean = true;

  @Output() saveEvent = new EventEmitter<string>();
  @Output() closeEvent = new EventEmitter<string>();


  ActionButton(action: string, rowData: any): void {
      if (action === 'save') {
        this.saveEvent.emit(rowData);

      }else{
        this.closeEvent.emit('close');
      }
  }

}
