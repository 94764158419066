
<app-dialog [title]="'Listado de programas'"
  class="mat-elevation-z8"
  [buttonAction]="true"
  (saveEvent)="saveMultiplePrograms()"
  (closeEvent)="close()">
  <app-table-data
  [data]="listPrograms"
  [dataSource]="dataSource"
  [columns]="displayedColumns"
  [pageSizeOptions]="[10, 15, 20]"
  [totalItems]="totalItems"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [selectButton]="true"
  [editButton]="false"
  [deleteButton]="false"
  [viewButton]="false"
  [multipleChoice]="multiChoise"
  (pageChange)="onPageChange($event)"
  (selectEvent)="toggleProgramSelection($event)"
  (selectCheckBoxEvent)="viewCheckedPrograms($event)"
>
</app-table-data>
</app-dialog>

