import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, throwError } from 'rxjs';
import { Project, Projects } from '@core/interfaces/project.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  public url: string = environment.base_url;
  public headers: any;


  constructor(private http: HttpClient) {


    this.headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization',`Bearer ${ localStorage.getItem('token_conhector') || ''}`)
    .set('idUser', localStorage.getItem('user_conhector') || ' ');

  }


  getProjectsList():Observable<Projects>{

    return this.http.get<Projects>(`${this.url}/configurations/projects`, {
      headers: this.headers,
    }). pipe(
      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las project list';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de getProjectsList: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    })
    )

  }

  getProjectById(id: string):Observable<Project> { 

    return this.http.get<Project>(`${this.url}/configurations/projects/${id}`, {
      headers: this.headers,
    }). pipe(      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las getProjectById';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de getProjectById: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    }))

  }

  registerProject( data: Project):Observable<Project> {

    return this.http.post<Project>(`${this.url}/configurations/projects`,data,{
      headers: this.headers,
    }). pipe(      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las registerProject';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de registerProject: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    }))
  }


  updateProject( data: Project, id: string): Observable<Project> {

    return this.http.put<Project>(`${this.url}/configurations/projects/${id}`, data ,{
      headers: this.headers,
    }). pipe(      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las updateProject';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de updateProject: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    }))

  }

  deleteProject( id: string ): Observable<Project> { 
    return this.http.delete<Project>(`${this.url}/configurations/projects/${id}`,{
      headers: this.headers,
    }). pipe(      catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de las deleteProject';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de deleteProject: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    }))
  }

}
