<app-dialog [title]="'Usuario'" (saveEvent)="eventData()"  (closeEvent)="closeEvent()">
  <form [formGroup]="userForm">
    <div class="row">

    <div class="tagus-form-group">
      <div class="col-lg-12 col-md-12">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Nombre</label>
        <mat-form-field appearance="fill">
          <i class="ri-file-text-line"></i>
          <mat-label>Escribe el nombre</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>
    </div>

    <div class="tagus-form-group">
      <div class="col-lg-12 col-md-12">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Apellido(s)</label>
        <mat-form-field appearance="fill">
          <i class="ri-file-text-line"></i>
          <mat-label>Escribe tus apellidos</mat-label>
          <input matInput formControlName="last_name" />
        </mat-form-field>
      </div>
    </div>

    <div class="tagus-form-group">
      <div class="col-lg-12 col-md-12">
        <label class="d-block mb-12 fw-semibold subtitle-card-color"
          >Correo electronico</label
        >
        <mat-form-field appearance="fill">
          <i class="ri-mail-open-line"></i>
            <mat-label>example&#64;fundacioncapital.org</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>
      </div>
    </div>

    <div class="tagus-form-group">
      <div class="col-lg-12 col-md-12">
        <label class="d-block mb-12 fw-semibold subtitle-card-color">Celular</label>
        <mat-form-field appearance="fill">
          <i class="ri-phone-line"></i>
          <mat-label>(555) 555-5555</mat-label>
          <input
            matInput
            [value]="userForm.value.phone ?? '' | formatPhone"
            formControlName="phone"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="tagus-form-group">
      <div class="col-lg-12 col-md-12">
        <div class="tagus-form-group">
          <label class="d-block mb-12 fw-semibold subtitle-card-color">Proyecto</label>
          <mat-form-field appearance="fill">
            <i class="ri-kanban-view-2"></i>
            <mat-label>Selecciona un tipo de Proyecto</mat-label>
            <mat-select formControlName="project">
              <mat-option disabled>Selecciona</mat-option>
              <mat-option
                *ngFor="let project of listProject"
                [value]="project.project"
                >{{ project.project }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>


  </form>
</app-dialog>
