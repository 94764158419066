<div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <h2 class="mb-0 me-30">
                            Restablecer la contraseña</h2>
                        <img src="assets/img/icons/2_pajaro.png" alt="logo-icon">
                    </div>
                    <p>
                      🔒 Su nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente🔒</p>
                </div>
                <form >
                  <div class="bg-white border-radius pt-20 ps-20 pe-20">
                    <div class="tagus-form-group without-icon">
                      <label class="d-block mb-12 fw-semibold gray-color">Contraseña Actual <span>*</span></label>
                      <mat-form-field appearance="fill">
                        <mat-label>Ingrese su contraseña actual</mat-label>
                        <input matInput [type]="hidePassword_actual ? 'password' : 'text'" [formControl]="previousPassword">
                        <button mat-icon-button matSuffix (click)="hidePassword_actual = !hidePassword_actual" [attr.aria-label]="'Ocultar contraseña'" [attr.aria-pressed]="hidePassword_actual">
                          <mat-icon>{{hidePassword_actual ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="previousPassword.invalid && (previousPassword.dirty || previousPassword.touched)">
                          La contraseña actual es requerida.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="tagus-form-group without-icon">
                      <label class="d-block mb-12 fw-semibold gray-color">Nueva Contraseña <span>*</span></label>
                      <mat-form-field appearance="fill">
                        <mat-label>Ingrese su nueva contraseña</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" [formControl]="newPassword">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Ocultar contraseña'" [attr.aria-pressed]="hide">
                          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
                          La nueva contraseña debe tener al menos 8 caracteres.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="tagus-form-group without-icon">
                      <label class="d-block mb-12 fw-semibold gray-color">Confirmar Contraseña <span>*</span></label>
                      <mat-form-field appearance="fill">
                        <mat-label>Confirme su nueva contraseña</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" [formControl]="confirmPassword">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Ocultar contraseña'" [attr.aria-pressed]="hide">
                          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                          La contraseña de confirmación es requerida.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <button mat-flat-button class="tagus d-block fw-semibold" (click)="resetPassword()" [disabled]="!previousPassword.valid || !newPassword.valid || !confirmPassword.valid">Establecer Nueva Contraseña</button>
                  <div class="text-center back-to-login">
                    <a routerLink="/login" class="d-inline-block main-color fw-medium position-relative"><i class="flaticon-chevron-1"></i> Volver al Inicio de Sesión</a>
                  </div>
                </form>
            </div>
        </div>
    </div>
</div>
