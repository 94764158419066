<div
    class="h-100vh pt-50 pb-50 login-page"
    [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-table">
        <div class="d-table-cell align-middle">
            <div class="tagus-form ms-auto me-auto border-radius login-form-container">
                <div class="text-center top border-top-radius position-relative bg-light-blue">
                    <h2 class="text-center mt-20">Bienvenido al cms de CON-HÉCTOR</h2>
                    <p class="text-center main-color">Hola, ingresa tu contraseña para desbloquear la pantalla.</p>
                </div>
                <div class="user bg-white text-center pt-30 ps-25 pe-25">
                    <img [src]="userInfo.img" class="rounded-circle" width="60" [alt]="userInfo.name">
                    <h5 class="mb-0 mt-8"><strong>{{userInfo.name}} {{userInfo.last_name}}</strong></h5>
                </div>
                <form [formGroup]="loginForm">
                    <div class="bg-white border-radius pt-20 ps-20 pe-20 pb-20">
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold font-color">Contraseña</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-icon matPrefix class="icon-color">lock</mat-icon>
                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Ingresa Contraseña" required>
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div class="d-flex info align-items-center justify-content-space-between">
                            <mat-checkbox class="icon-color font-color">Recordar mis datos</mat-checkbox>
                            <a [routerLink]="['/login']" class="d-inline-block main-color fw-medium">Iniciar Sesión con otro Usuario</a>
                        </div>
                        <div class="d-flex info align-items-center justify-content-center">
                            <button mat-flat-button class="tagus d-block fw-semibold mt-20 w-100 login-button" (click)="login()" [disabled]="loginForm.invalid">Ingresar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
