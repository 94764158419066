import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams,} from '@angular/common/http';

import { Line, Lines } from '@core/interfaces/lines.interface';
import { environment } from 'src/environments/environment';
import { Group, Groups } from '@core/interfaces/group.interface';

@Injectable({
  providedIn: 'root',
})
export class LinesGroupsService {

  public urlBackEnd: String = environment.base_url;
  public headers: any;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${localStorage.getItem('token_conhector')}`)
      .set('iduser', `${localStorage.getItem('user_conhector')}`);
  }

  getListLines(): Observable<Lines> {
    return this.http
      .get<Lines>(`${this.urlBackEnd}/lines`, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  /**
   * Obtiene una lista paginada de lineas.
   * @param {number} page - Número de página.
   * @param {number} limit - Límite de lineas por página.
   * @returns {Observable<Programs>} - Observable que emite un objeto con la lista paginada de lineas.
  */

  getListLinesPaginate (page: number, limit: number): Observable<Lines> {
    //Configurar paramentros de consulta
    const params = new HttpParams()
    .set('page', page.toString())
    .set('limit', limit.toString());
   return this.http
     .post<Lines>(`${this.urlBackEnd}/lines/paginate`, {}, { params: params, headers: this.headers })
     .pipe(
       catchError((error: HttpErrorResponse) => {
         let errorMessage =
           'Ocurrió un error en la solicitud de las lineas';
         if (error.error instanceof ErrorEvent) {
           // Error del cliente, como red no disponible
           console.error('Error del cliente:', error.error.message);
         } else {
           // El servidor devolvió un código de error
           console.error(
             `Error en la solicitud de getListLines: ${error.status}, ` +
               `Mensaje de error: ${error.error.msg}`
           );
           errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
         }
         // Devuelve el mensaje de error al componente
         return throwError(error);
       })
     );
 }

  registerLine(data: Line): Observable<Lines> {

    return this.http.post<Lines>(`${this.urlBackEnd}/lines`, data, { headers: this.headers })
      .pipe(catchError((error: HttpErrorResponse) => {
        let errorMessage =
          'Ocurrió un error en la solicitud de las lineas';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de registerLine: ${error.status}, ` + `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(error);
      }));
  }


  getLinesById(id: string): Observable<Lines> {
    return this.http
      .get<Lines>(`${this.urlBackEnd}/lines/${id}`, { headers: this.headers })
      .pipe( catchError((error: HttpErrorResponse) => {
        let errorMessage =
          'Ocurrió un error en la solicitud de las lineas *getLinesById*';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de getLinesById: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(error);
      }));
  }

  deletLineById(id: string, line: string): Observable<any> {
    return this.http
        .delete(`${this.urlBackEnd}/lines/${id}/${line}`, { headers: this.headers })
        .pipe(catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de la línea ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de deleteLineById: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        }));
  }


  updateLineById(data: any, id: string): Observable<any> {
    return this.http
      .put(`${this.urlBackEnd}/lines/${id}`, data, { headers: this.headers })
      .pipe(catchError((error: HttpErrorResponse) => {
        let errorMessage =
          'Ocurrió un error en la solicitud de la línea ';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de updateLineById: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(error);
      }));
  };

 //----------------------------------------------
 //---------------Grupos-------------------------
 //----------------------------------------------


 createGroup(data: Group | Group[]): Observable<Groups> {
  return this.http
    .post<Groups>(`${this.urlBackEnd}/groups`, data, { headers: this.headers })
    .pipe(catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de los grupos ';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de registerGroup: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    }));
}

 filterGroupsForLines(idLine: string): Observable<Groups> {
  return this.http
    .get<Groups>(`${this.urlBackEnd}/groups/filterGroups/${idLine}`, {
      headers: this.headers,
    })
    .pipe(catchError((error: HttpErrorResponse) => {
      let errorMessage =
        'Ocurrió un error en la solicitud de los grupos ';
      if (error.error instanceof ErrorEvent) {
        // Error del cliente, como red no disponible
        console.error('Error del cliente:', error.error.message);
      } else {
        // El servidor devolvió un código de error
        console.error(
          `Error en la solicitud de filterGroupsForLines: ${error.status}, ` +
            `Mensaje de error: ${error.error.msg}`
        );
        errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
      }
      // Devuelve el mensaje de error al componente
      return throwError(error);
    }));
  }

  updateGroupById(data: any, id: string): Observable<any> {

    return this.http
      .put(`${this.urlBackEnd}/groups/${id}`, data, { headers: this.headers })
      .pipe(catchError((error: HttpErrorResponse) => {
        let errorMessage =
          'Ocurrió un error en la solicitud de los grupos ';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de updateGroupById: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(error);
      }));
  }

  deleteGroupById(id: string, group: string, line: string): Observable<any> {
    return this.http
        .delete(`${this.urlBackEnd}/groups/${id}/${group}/${line}`, { headers: this.headers })
        .pipe(catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de los grupos ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de deleteGroupById: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        }));
    }


}
