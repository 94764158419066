<app-card class="mb-25 tagus-card">
  <mat-card-content>


    <app-table-data
      [data]="listParticipants"
      [dataSource]="dataSource"
      [columns]="displayedColumns"
      [pageSizeOptions]="[10, 20, 50, 100, 200]"
      [totalItems]="totalItems"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      (pageChange)="onPageChange($event)"
    ></app-table-data>
  </mat-card-content>
</app-card>
