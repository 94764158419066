import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateTransform'
})
export class FormateState implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'A':
        return 'Activo';
      case 'I':
        return 'Inactivo';
      case 'E':
        return 'Expirado';
      default:
        return value; // Devuelve el valor original si no coincide con ningún estado conocido
    }
  }

}
