import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Group, Groups } from '@core/interfaces/group.interface';
import { Project, Projects } from '@core/interfaces/project.interface';
import { ProjectsService } from '@core/services/configurations/projects.service';
import { LinesGroupsService } from '@core/services/linesGroups/lines-groups.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import Swal from 'sweetalert2';
import { Line, Lines } from '@core/interfaces/lines.interface';
import { CampaignsService } from '@core/services/campaigns/campaigns.service';
import { DialogViewCampaignComponent } from '../dialog-view-campaign/dialog-view-campaign.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-edit-line',
  templateUrl: './create-edit-line.component.html',
  styleUrls: ['./create-edit-line.component.scss'],
})
export class CreateEditLineComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'description', 'action'];
  public title: string = '';
  public listProject: Project[] = [];
  public listGroup: Group[] = [];
  dataSource = new MatTableDataSource<Group>(this.listGroup);
  public idLines: string = '';
  public linesForm: FormGroup;
  public lineName: string = '';

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  constructor(
    public themeService: CustomizerSettingsService,
    private _projectService: ProjectsService,
    private campaignsService: CampaignsService,
    private fb: FormBuilder,
    private route: Router,
    private router: ActivatedRoute,
    private _linesGroupService: LinesGroupsService,
    public dialog: MatDialog
  ) {
    this.linesForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      indicative: ['', [Validators.required, Validators.maxLength(3)]],
      description: [''],
      project: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getListProject();
    if (this.router.snapshot.paramMap.get('id')) {
      this.idLines = this.router.snapshot.paramMap.get('id') || '';
      this.getInfoLines();
    }
  }

  ngOnDestroy(): void {
    this.listProject = [];
    this.listGroup = [];
  }

  getListProject() {
    this._projectService.getProjectsList().subscribe(
      (resp: Projects) => {
        this.listProject = resp.results;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getInfoLines() {
    this._linesGroupService.getLinesById(this.idLines).subscribe(
      (res) => {
        if (res.ok) {
          this.linesForm.patchValue({
            name: res.results[0].name,
            indicative: res.results[0].indicative,
            description: res.results[0].description,
            project: res.results[0].project,
          });
          this.lineName = res.results[0].name;
          this.getListGroup();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getListGroup() {
    if (this.idLines) {
      this._linesGroupService.filterGroupsForLines(this.idLines).subscribe(
        (resp: Groups) => {
          if (resp.ok) {
            this.listGroup = resp.results ? resp.results : this.listGroup;
            this.dataSource = new MatTableDataSource(this.listGroup);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  addNewGroupRow() {
    if (this.listGroup.some(group => group.isEditing)) {
      Swal.fire({
        icon: 'warning',
        title: 'Complete la edición actual antes de agregar un nuevo grupo',
      });
      return;
    }

    const newGroup: Group = { id: '', name: '', description: '', idLines: this.idLines, isEditing: true };
    this.listGroup.push(newGroup);
    this.dataSource = new MatTableDataSource(this.listGroup);
  }

  saveGroup(group: Group) {
    console.log(group)
    if (this.idLines) {
        // Validaciones manuales
      const errors = this.validateGroup(group);

      if (errors.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: errors.join('<br>')
        });
        return;
      }

      let saveGroup: Group = {
        name: group.name || '',
        description: group.description || '',
        idLines: this.idLines,
      };

      if (group.id) {
        this._linesGroupService.updateGroupById(saveGroup, group.id).subscribe(
          (resp) => {
            if (resp.ok) {
              this.showSwal( 'success', 'Grupo actualizado con éxito' )
              group.isEditing = false;
            }
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              title: err.error.msg
            });
            group.isEditing = true;
          }
        );
      } else {
        this._linesGroupService.createGroup(group).subscribe(
          (resp) => {
            if (resp.ok) {
              this.Toast.fire({ icon: 'success', title: 'Grupo creado con éxito' });
              this.listGroup = this.listGroup.map((group) => group.name === resp.data!.name ? resp.data!: group);
              this.dataSource = new MatTableDataSource(this.listGroup);
              group.isEditing = false;
            }
          },
          (err) => {
            this.listGroup = this.listGroup.filter((g) => g !== group ) ;
            this.dataSource = new MatTableDataSource(this.listGroup);
            Swal.fire({
              icon: 'error',
              title: err.error.msg
            });
          }
        );
      }
    }

  }

  validateGroup(group: Group): string[] {
    const errors: string[] = [];

    if (!group.name) {
      errors.push('El nombre es requerido.');
    } else {
      if (group.name.length < 2) {
        errors.push('El nombre debe tener al menos 2 caracteres.');
      }
      if (group.name.length > 50) {
        errors.push('El nombre no puede tener más de 50 caracteres.');
      }
    }

    return errors;
  }

  cancelGroup(rowGroups: any) {
    if (rowGroups[0].id) {
      this.listGroup = this.listGroup.map(g => g.id === rowGroups[1].id ? {...rowGroups[1], isEditing: false} : g);
    }
    else {
    this.listGroup = this.listGroup.filter(g => g !== rowGroups[0]);
    }

    this.dataSource = new MatTableDataSource(this.listGroup);
/*  this.listGroup = this.listGroup.filter(g => g !== group);
    this.dataSource = new MatTableDataSource(this.listGroup); */
  }

  saveLine() {
    if (this.linesForm.valid) {
      const lineData: Line = {
        name: this.linesForm.value.name,
        indicative: this.linesForm.value.indicative,
        project: this.linesForm.value.project,
        description: this.linesForm.value.description,
      };

      if (this.idLines) {
        this._linesGroupService.updateLineById(lineData, this.idLines).subscribe(
          (resp) => {
            if (resp.ok) {
              Swal.fire({
                icon: 'success',
                title: 'Línea actualizada con éxito',
                iconHtml: '<i class="ri-check-line" style="color: green;"></i>',
                showConfirmButton: false,
                timer: 3000,
                backdrop: false,
                toast: true,
                position: 'top-end',
                background: '#ffffff',
                color: '#1d2c4d',
                padding: '1rem',
                width: '25rem',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              });

              this.route.navigate(['lines/lists']);
            }
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              title: err.error.msg
            });
          }
        );
      } else {
        this._linesGroupService.registerLine(lineData).subscribe(
          async (resp: Lines) => {
            if (resp.ok) {
              this.idLines = resp.results[0].id || '';
              await this.refactorData();
              for (let index = 0; index < this.listGroup.length; index++) {
                this.createOrUpdateGroup('created', this.listGroup[index]);
              }
              this.route.navigate(['lines/lists']);
            }
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              title: err.error.msg
            });
          }
        );
      }
    } else {
      this.showSwal( 'error', 'Por favor, completa todos los campos requeridos.' )
    }
  }

  async refactorData() {
    let listGroupProvitional: Group[] = [];

    for (let index = 0; index < this.listGroup.length; index++) {
      const element = this.listGroup[index];
      let group: Group = { name: element.name || '', description: element.description || '', idLines: this.idLines };
      listGroupProvitional.push(group);
    }
    this.listGroup = listGroupProvitional;
  }

  private createOrUpdateGroup(options: string, group: Group): void {
    if (options === 'created') {
      this._linesGroupService.createGroup(group).subscribe(
        (resp) => {
          if (resp.ok) {
            this.Toast.fire({
              icon: 'success',
              title: 'Se ha registrado la nueva Linea',
            });
          }
        },
        (err) => {
           Swal.fire({
            icon: 'error',
            title: err.error.msg
          });
        }
      );
    } else {
      this._linesGroupService.createGroup(group).subscribe(
        (resp) => {
          if (resp.ok) {
            this.Toast.fire({
              icon: 'success',
              title: 'Se ha registrado el nuevo Grupo',
            });
            this.getListGroup();
          }
        },
        (err) => {
           Swal.fire({
            icon: 'error',
            title: err.error.msg
          });
        }
      );
    }
  }

  async deleteGroup(data: any): Promise<void> {
    const { value: confirmDelete } = await Swal.fire({
      title: '¿Estás seguro?',
      text: `Vas a eliminar el grupo ${data.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#262D48', // Color del botón "Sí, eliminarlo"
      cancelButtonColor: '#FF6D39', // Color del botón "Cancelar"
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      width: '30rem',
    });

    if (confirmDelete) {
      const group = this.listGroup.filter((group) => group.id !== data.id);
      const groupOptions = group.map((group) => group.name);

      const { value: groupName } = await Swal.fire({
        title: 'Seleccionar grupo',
        text: 'Selecciona un grupo para migrar la información antes de eliminar el grupo seleccionado',
        input: 'select',
        inputOptions: groupOptions,
        inputPlaceholder: 'Selecciona un grupo',
        showCancelButton: true,
        confirmButtonColor: '#262D48', // Color del botón "Seleccionar"
        confirmButtonText: 'Continuar',
        cancelButtonColor: '#FF6D39', // Color del botón "Cancelar"
        cancelButtonText: 'Cancelar',
        width: '30rem',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value !== '') {
              resolve();
            } else {
              resolve('Debes seleccionar un grupo para migrar la información');
            }
          });
        },
      });

      const groupToMigrate = group[groupName];
      if (groupToMigrate) {
        Swal.fire({
          title: '¿Seguro de realizar el cambio?',
          text: `Se va a realizar la migración al grupo ${groupToMigrate.name} y se eliminará el grupo ${data.name}`,
          showDenyButton: true,
          confirmButtonText: 'Migrar',
          denyButtonText: `Cancelar`,
          confirmButtonColor: '#262D48', // Color del botón "Continuar"
          denyButtonColor: '#FF6D39', // Color del botón "No Guardar"
          width: '30rem',
        }).then((result) => {
          if (result.isConfirmed) {
            if (data.id && groupToMigrate.id) {
              this._linesGroupService
                .deleteGroupById(data.id, groupToMigrate.id, this.idLines)
                .subscribe(
                  (resp) => {
                    if (resp.ok) {
                      Swal.fire({
                        title: 'Grupo eliminado',
                        text: `Se migraron ${resp.data} participantes al grupo ${groupToMigrate.name}`,
                        icon: 'success',
                        confirmButtonColor: '#262D48',
                        width: '30rem',
                        customClass: {
                          confirmButton: 'swal-custom-confirm',
                          popup: 'swal-custom-popup',
                          title: 'swal-custom-title',
                        }
                      });
                      this.getListGroup();
                    }
                  },
                  (err) => {
                    Swal.fire({
                      icon: 'error',
                      title: err.error.msg,
                      confirmButtonColor: '#FF6D39',
                      width: '30rem',
                    });
                  }
                );
            } else {
              console.log('Invalid id or groupToMigrate.id');
            }
          } else if (result.isDenied) {
            Swal.fire({
              title: 'Proceso cancelado',
              text: 'Se ha cancelado el proceso',
              icon: 'info',
              confirmButtonColor: '#262D48',
              width: '30rem',
            });
          }
        });
      } else {
        Swal.fire({
          title: 'No se seleccionó grupo',
          text: 'No se ha seleccionado ningún grupo para migrar la información',
          icon: 'info',
          confirmButtonColor: '#FF6D39',
          width: '30rem',
        });
      }
    }
  }


  editCampain(data: any): void {
    this.campaignsService.getCampaignByGroup(data.id).subscribe(
      (response) => {
        if (response.ok && response.data) {
          const dialogRef = this.dialog.open(DialogViewCampaignComponent, {
            width: '60vw',
            data: response.data
          });

          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.route.navigate(['lines/line-campaign', data.id], { queryParams: { name: data.name, line: this.lineName, line_id: this.idLines} });
            }
          });
        } else {
          this.route.navigate(['lines/line-campaign', data.id], { queryParams: { name: data.name, line: this.lineName, line_id: this.idLines } });
        }
      },
      (error) => {
        this.showSwal('error', 'Ocurrió un error al verificar la campaña')
      }
    );
  }

  // Método centralizado para mostrar los mensajes de Swal
  private showSwal(icon: 'success' | 'error' | 'warning' | 'info', text: string) {
    Swal.fire({
      icon: icon,
      iconHtml: icon === 'success' ? '<i class="ri-check-line" style="color: green;"></i>' : undefined,
      text: text,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      color: '#1d2c4d',
      confirmButtonColor: '#262D48',
      background: '#ffffff',
      width: '28rem',
    });
  }

  onPageChange(event: any) {}

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }
}
