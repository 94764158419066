import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import {Participants, ParticipantsFilter, ParticipantsPaginate } from '@core/interfaces/participants.interface';

@Injectable({
  providedIn: 'root'
})

export class ParticipantsService {
  public urlBackEnd: string = environment.base_url;
  public userData: any;
  public headers: any;
  public tokenUser: string = '';

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${localStorage.getItem('token_conhector')}`)
      .set('iduser', `${localStorage.getItem('user_conhector')}`);

  }


  getListParticipants(): Observable<Participants> {
    return this.http
      .get<Participants>(`${this.urlBackEnd}/participants`, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de los participantes';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  /**
   * Obtiene una lista paginada de participantes con filtros.
   * @param {number} page - Número de página.
   * @param {number} limit - Límite de participantes por página.
   * @param {ParticipantsFilter} filters - Filtros opcionales para la búsqueda de participantes.
   * @returns {Observable<ParticipantsPaginate>} - Observable que emite un objeto con la lista paginada de participantes.
  */
  getListParticipantsPaginate(page: number, limit: number, filters?: ParticipantsFilter ): Observable<ParticipantsPaginate> {
    //Establecer body de la solicitud
    let body: ParticipantsFilter = {};

    let params = new HttpParams()
    .set('page', page.toString())
    .set('limit', limit.toString());

    if (filters) {
      if (Object.keys(filters).length > 0) {
        body['filters'] = filters
      }
    }

    return this.http
      .post<ParticipantsPaginate>(`${this.urlBackEnd}/participants/participants-paginate`, body, { params: params, headers: this.headers})
      .pipe(

        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de los participantes';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  /**
   * Obtiene los filtros disponibles para la búsqueda de participantes.
   * @returns {Observable<ParticipantsPaginate>} - Observable que emite un objeto con los filtros disponibles.
  */
  getListFiltersParticipants(): Observable<ParticipantsPaginate> {

    return this.http
      .get<ParticipantsPaginate>(`${this.urlBackEnd}/participants/participants-filters`, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de los participantes';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }
}
