<div class="create-dialog-box scrollable-dialog">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Formulario grupos</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="closed()"><i class="flaticon-close"></i></button>
    </div>
    <form autocomplete="off"  novalidate  [formGroup]="groupForm">
        <div class="row">
            <h5 class="fw-semibold mb-8 mt-5">Grupos</h5>
            <p>Ingresa el listado de grupos, <br> <strong>*Recuerda debe existir al menos un grupo para guardar la linea</strong></p>
            <div class="col-lg-12 col-md-6 col-sm-6">
                <div class="tagus-form-group">
                    <label class="d-block mb-12 fw-semibold gray-color">grupo</label>
                    <mat-form-field appearance="fill">
                       <i class="ri-text-block"></i>
                        <input matInput placeholder="nombre del grupo" formControlName="name">

                    </mat-form-field>
                    <div *ngIf="groupForm.controls['name'].invalid && (groupForm.controls['name'].dirty || groupForm.controls['name'].touched)" class="text-danger">
                        <div *ngIf="groupForm.controls['name'].errors?.['required']">
                             El nombre es requerido.
                        </div>
                        <div *ngIf="groupForm.controls['name'].errors?.['minlength']">
                            El nombre debe tener al menos 2 caracteres.
                        </div>
                        <div *ngIf="groupForm.controls['name'].errors?.['maxlength']" >
                            El nombre no puede tener más de 50 caracteres.
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="col-lg-12 col-md-6 col-sm-6">
                <div class="tagus-form-group">
                    <label class="d-block mb-12 fw-semibold gray-color">Descripción</label>
                    <mat-form-field appearance="fill">
                        <i class="ri-information-line"></i>
                        <mat-label>Describe el grupo...</mat-label>
                        <textarea matInput rows="2" formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </div>
        
        </div>
        <div class="text-end">
            <button type="submit" (click)="close()" [disabled]="groupForm.invalid">
                <i class="ri-add-fill"></i> Agregar
            </button>
            <div *ngIf="groupForm.invalid" class="text-danger">
                Por favor, complete el formulario correctamente.
            </div>
        </div>
    </form>
</div>