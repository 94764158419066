import { Injectable } from '@angular/core';
import { CanActivateChild, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Modules } from '@core/interfaces/modules.interface';
import Swal from 'sweetalert2';
import { ModulesService } from '@core/services/configurations/modules.service';

@Injectable({
  providedIn: 'root'
})
export class ModulePermissionGuard implements CanActivateChild {
  constructor(private _modulesService: ModulesService, private router: Router) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url = state.url;
    const segments = url.split('/').slice(0, 3);

    return this._modulesService.modulePermission(segments[1]).pipe(
      tap((modules: any) => {

        if (!modules.ok) {
          this.router.navigate(['/']);
          Swal.fire({
            icon: 'error',
            title: 'Acceso denegado',
            text: 'No tienes permisos para acceder a este módulo',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }),
      map(() => true)
    );
  }
}
