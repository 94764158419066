import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Line, Lines } from '@core/interfaces/lines.interface';
import { LinesGroupsService } from '@core/services/linesGroups/lines-groups.service';
import { MatchService } from '@core/services/programs/match.service';
import { DiagramComponent } from '@modules/programs/components/diagram/diagram.component';
import { DialogAddLevelComponent } from '@modules/programs/components/dialog-add-level/dialog-add-level.component';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-list-match',
  templateUrl: './list-match.component.html',
  styleUrls: ['./list-match.component.scss']
})
export class ListMatchComponent {

  panelOpenState = false;
  listProgram: any[] = [];
  dataGroup: any;
  listLine: Line[] = [];

  myControl = new FormControl<string | Line>('');
  filteredOptions!: Observable<Line[]>;
  public lineSelect: string = '';
  public idLine: string = '';

  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedPanels: { [key: number]: number | null } = {};

  @ViewChild(DiagramComponent) diagramComponent!: DiagramComponent;

  constructor(
    private _linesGroupsService: LinesGroupsService,
    private matchService: MatchService,
    public dialog: MatDialog
  ) {
    this.getListLines();
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.listLine.slice();
      })
    );
  }

  displayFn(line: Line): string {
    return line && line.name ? line.name : '';
  }

  private _filter(name: string): Line[] {
    const filterValue = name.toLowerCase();
    return this.listLine.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  getListLines(): void {
    this._linesGroupsService.getListLines().subscribe(
      (resp: Lines) => {
        if (resp.ok) {
          this.listLine = resp.results;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  filterGroupsByLines(id: string) {
    this.lineSelect = id;
    this.matchService.getListMatchByIdLine(this.lineSelect).subscribe(
      (res: any) => {
        if (res.ok) {
          this.dataGroup = res.data;
          this.expandedPanels = {}; // Reseteamos los índices expandidos al cambiar de línea
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  optionSelected(line: Line) {
    this.idLine = line.id || ' ';
    this.filterGroupsByLines(this.idLine);
  }

  registerNewNivel(data: any, id: any) {
    let newLevel = {
      level: data.level,
      subPrograms: {
        position: data.position,
        program: data.program,
      },
    };

    for (let index = 0; index < this.dataGroup.length; index++) {
      const element = this.dataGroup[index];
      if (element.group === id) {
        element.levels.push(newLevel);
      }
    }
    this.expandedPanels[id] = this.dataGroup.length - 1;
  }

  eventReload() {
    this.filterGroupsByLines(this.idLine);
  }

  changeInfo(data: any) {
    console.log("mi data", data)
    if (data?.[0]?.method === 'update') {
      // Si data es un array
      if (Array.isArray(data)) {

        // Crear un Map para almacenar las respuestas actualizadas
        const updatedProgramsMap = new Map(
          data.map((item: any) => [item.response.group._id, item.response]) // Mapear los datos por _id del grupo
        );

        // Recorrer dataGroup y actualizar los programas que están en el Map
        this.dataGroup = this.dataGroup.map((program: any) =>
          updatedProgramsMap.get(program.group._id) || program // Si existe en el Map, actualizar, si no, dejar el actual
        );
      }

    } else if (data?.method === 'update') {
      // Si data es un objeto único (no array)
      this.dataGroup = this.dataGroup.map((program: any) =>
        program.group._id === data.response.group._id ? data.response : program
      );

    } else if (data?.method === 'deleteFirst') {
      this.eventReload()
    } else if (data?.method === 'delete') {
      this.dataGroup = this.dataGroup.map( (match: any) => match.id === data.response.id ? data.response: match)
    }
  }

  addNewFirstLevel(match: any, info: any) {
    let position = info.length + 1;
    let data = {
      match: match.id,
      level: 1,
      position: position.toString(),
      program: ' ',
      children: [],
    };

    const dialogRef = this.dialog.open(DialogAddLevelComponent, {
      width: '70vw',
      data: { isUpdate: 'returnsId', body: data,  multipleChoise: true },
    });

     // Escuchar el EventEmitter desde el diálogo
     dialogRef.componentInstance.loadData.subscribe((levelData: any) => {
      console.log('Nuevo nivel agregado:', levelData);
      // Aquí puedes manejar el nivel agregado, por ejemplo, actualizando tu lista
    });


    dialogRef.afterClosed().subscribe((result) => {
      console.log("result", result)
      if (result) {
        // Preservamos el índice actual antes de recargar los datos
        const previousExpandedIndex = this.expandedPanels[match.group._id];
        this.filterGroupsByLines(this.idLine);
        // Restauramos el índice expandido después de recargar los datos
        this.expandedPanels[match.group._id] = previousExpandedIndex === null ? info.length : previousExpandedIndex;
      }
    });
  }

  isPanelExpanded(groupIndex: number, panelIndex: number): boolean {
    return this.expandedPanels[groupIndex] === panelIndex;
  }

  panelOpened(groupIndex: number, panelIndex: number): void {
    this.expandedPanels[groupIndex] = panelIndex;
  }

  panelClosed(groupIndex: number, panelIndex: number): void {
    if (this.expandedPanels[groupIndex] === panelIndex) {
      this.expandedPanels[groupIndex] = null;
    }
  }
}
