<div class="create-dialog-box">
  <div class="title d-flex align-items-center justify-content-space-between">
    <h4 class="mb-0 subtitle-card-color">
      Listado de usuarios para el perfil {{ data.nameProfile }}
    </h4>
    <button class="close-btn bg-transparent p-0 border-none" (click)="close()">
      <i class="flaticon-close"></i>
    </button>
  </div>
  <div class="col-lg-12 col-md-12">
    <mat-card class="mb-20 tagus-card">
      <mat-card-header>
        <h5 class="mb-0 subtitle-card-color">Usuarios</h5>
      </mat-card-header>
      <mat-card-content>
        <div class="team-members-table table-responsive">
          <form class="example-form">
            <div class="col-lg-12 col-md-6 col-sm-6">
              <div class="tagus-form-group custom-style">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Registra nuevo Usuario</mat-label>
                  <input
                    type="text"
                    placeholder="Digita el Nombre del Usuario"
                    aria-label="Number"
                    matInput
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option
                     (onSelectionChange)="selectUser(option.id || '' )"
                      *ngFor="let option of filteredOptions | async"
                      [value]="option.name + ' ' + option.last_name"
                    >
                      {{ option.name }} {{ option.last_name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <button mat-flat-button class="default-btn bg-main-color-blue" (click)="registerUserToProfile() ">Guardar</button>
          </form>

          <mat-divider></mat-divider>

          <table class="table">
            <thead>
              <tr>
                <th class="fw-semibold subtitle-card-color">Usuario</th>
                <th class="fw-semibold subtitle-card-color">Email</th>
                <th class="fw-semibold subtitle-card-color">Estado</th>
                <th class="fw-semibold subtitle-card-color">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of listUserByProfile">
                <td class="subtitle-card-color">
                  <div class="user-info d-flex align-items-center">
                    <img
                      [src]="user.img"
                      class="rounded-circle"
                      alt="user"
                      width="30"
                    />
                    <div class="title">
                      {{ user.name }} - {{ user.last_name }}

                    </div>
                  </div>
                </td>
                <td class="subtitle-card-color" [matTooltip]="user.email ?? ''">{{ user.email ?? '' | truncate:20}}</td>
                <td class="subtitle-card-color">

                  <span class="badge fw-medium" *ngIf="user.state === 'A'"
                    >Activo</span
                  >
                  <span
                    class="badge inactive fw-medium"
                    *ngIf="user.state === 'I'"
                    >Inactivo</span
                  >
                </td>
                <td>
                  <button
                    type="button"
                    mat-button
                    class="position-relative dot-btn p-0 bg-transparent border-none"
                    [matMenuTriggerFor]="actionMenu"
                  >
                    <i class="ri-more-fill"></i>
                  </button>
                  <mat-menu
                    #actionMenu="matMenu"
                    class="dotMenu two monthMenu"
                    xPosition="before"
                  >
                    <button
                      mat-menu-item
                      type="button"
                      class="bg-transparent border-none position-relative"
                      (click)="changeStatusUser(user.id || '')"
                    >
                      <span *ngIf="user.state === 'A'"
                        ><i class="flaticon-dislike"></i>Inactivar</span
                      >
                      <span *ngIf="user.state === 'I'"
                        ><i class="flaticon-like"></i>Activar</span
                      >
                    </button>

                    <button
                      mat-menu-item
                      type="button"
                      class="bg-transparent border-none position-relative"
                      (click)="deleteProfileUser(user.id || '')"
                    >
                      <i class="ri-delete-bin-line"></i> Eliminar
                    </button>
                  </mat-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
