import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListParticipantsComponent } from './pages/list-participants/list-participants.component';

const routes: Routes = [
  {
  path: 'list',
  component: ListParticipantsComponent,
    data:{
      firstBreadcrumb: {
        title: 'Con-Héctor',
        icon: '../../../assets/img/ConHector/Personaje_acciones/icon.png',
        url: '/'
      },
      title: 'Listado de Participantes',
      url: 'participants/list'

    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParticipantsRoutingModule { }
